import React from 'react';
import Footer from '../components/navbar/Footer';
import Logo from '../img/logo.png';
import { Helmet } from 'react-helmet';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="flex items-center flex-col ">
                    <Helmet>
                        <title>Dabxlabs — Unexpected Error</title>
                        <meta name="robots" content="noindex" />
                    </Helmet>


                    <img alt="logo" src={Logo} className="w-48 mb-12 md:w-64 group-hover:opacity-75 cursor-pointer transition ease-in-out duration-300"/>
                    <h1 className="text-center text-3xl font-bold mb-12">404. Something went wrong.</h1>
                    <Footer/>
                </div>
            )
        }

        return this.props.children; 
    }
}

export default ErrorBoundary;