import { createSlice } from '@reduxjs/toolkit';

export const ageVerifySlice = createSlice({
  name: 'ageverify',
  initialState: {
    over18: false
  },
  reducers: {
    verifyAge: (state) => {
        state.over18 = true
    }
  },
});

export const { verifyAge } = ageVerifySlice.actions;
export const selectAgeVerify = (state) => state.ageReducer.over18;
export default ageVerifySlice.reducer;
