import SmokeElement from "smoke-effect-react";
import { selectAgeVerify, verifyAge } from "./AgeVerificationSlice";
import { useSelector, useDispatch } from "react-redux";
import Logo from '../../img/logo.png';
import { motion } from 'framer-motion';

const AgeVerification = (props) => {
    const over18 = useSelector(selectAgeVerify);
    const dispatch = useDispatch();

    const enterSite = () => {
        dispatch(verifyAge());
    };

    return (
        <motion.div key="age" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 2 }}>
            {
                !over18 
                &&  <div className="fixed top-0 left-0 w-full h-full bg-black">
                    <div className="container mx-auto items-center justify-center align-center flex m-24 flex-col">
                        <img alt="logo" src={Logo} className="w-48 md:w-64 group-hover:opacity-75 transition ease-in-out duration-300"/>
                    </div>

                    <div className="container mx-auto items-center text-center justify-center align-center flex mt-16 flex-col">
                        <h1 className="text-4xl font-bold font-hero">Confirm your age.</h1>
                        <h3 className="text-md font-hero mt-8">We require users to be 18+. By entering this site, you confirm that you are over the age of 18.</h3>

                        <button onClick={enterSite} className="bg-neutral-800 font-semibold mt-12 w-64 p-6 rounded-md hover:bg-neutral-600 transition-all ease-in-out duration-300">I confirm I am 18+</button>
                    </div>

                    <div className="smoke flex items-center fixed top-0 left-0 -z-50 w-screen h-screen overflow-hidden">
                        <SmokeElement
                            src=""
                            opacity="0.1"
                            smokeSrc="https://s3-us-west-2.amazonaws.com/s.cdpn.io/95637/Smoke-Element.png"
                            smokeOpacity="0.1"
                        />
                    </div>
                </div>
            }

            { over18 && props.children }
      </motion.div>
    );
}

export default AgeVerification;