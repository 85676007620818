import Navbar from '../components/navbar/Navbar';
import Footer from '../components/navbar/Footer';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { clear, selectCart } from '../components/cart/CartSlice';
import { Button, Callout, Card, Col, Divider, Grid, TextInput } from '@tremor/react';
import { useState } from 'react';
import ImageHelper from '../components/ImageHelper';
import { ExclamationIcon } from '@heroicons/react/solid';
import { ReactComponent as Loading }  from '../img/loadingicon.svg';
import { useEffect } from 'react';
import useFetch from 'use-http'
import { useSnackbar } from 'react-simple-snackbar';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../components/auth/UserSlice';
import FirestoreHelper from '../components/FirestoreHelper';
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { useRef } from 'react';

const shippings = [
    { id: "AUD", name: "Auspost Domestic", estimated: "Between 4 and 12 business days", price: 10.00 },
    { id: "AUE", name: "Auspost Express", estimated: "Between 2 and 5 business days", price: 20.00 },
    { id: "APE", name: "Australia Post Express", estimated: "Between 2 and 6 business days", price: 18.00 },
]

const mandatory = (errors, text, value) => {
    if (!value || value === "") {
        errors.push(`A value for ${text} is required.`);
    }
};

const validateEmail = (errors, email) => {
    const isValid = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

    if (!isValid) {
        errors.push('Please provide a valid email.');
    }
  };

const Checkout = () => {
    const cart = useSelector(selectCart);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);

    const [userDetails, setUserDetails] = useState(null);
    const [email, setEmail] = useState(user ? user.email : "");
    const [loading, setLoading] = useState(true);
    const [placing, setPlacing] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [streetAddress2, setStreetAddress2] = useState("");
    const [suburb, setSuburb] = useState("");
    const [state, setState] = useState("");
    const [postcode, setPostcode] = useState("");
    const [country, setCountry] = useState("");
    const [phone, setPhone] = useState("");
    const [saveShipping, setSaveShipping] = useState(true);
    const recaptchaRef = useRef();

    const [selectedShipping, setShipping] = useState(shippings[0].id);
    const [errors, setErrors] = useState([]);
    const { post, response, loading: checkingOut } = useFetch('https://us-central1-dabxlabs.cloudfunctions.net/checkout');
    const [openSnackbar] = useSnackbar({ position: 'top-center', style: { backgroundColor: '#22c55e', color: 'white', fontWeight: 'bold' }});

    const shippingItem = shippings.find((x) => x.id === selectedShipping);
    const subtotal = cart.items.map(x => parseFloat(x.salePrice && x.salePrice > 0 ? x.salePrice : x.price) * x.quantity).reduce((a, b) => a + b, 0);
    const shippingCost = shippingItem.price;
    const total = subtotal + shippingCost;

    useEffect(() => {
        setTimeout(() => {
            FirestoreHelper
                .getUserDetails(user)
                .then((details) => { 
                    if (details?.addresses?.length > 0) {
                        const address = details.addresses[0];

                        setFirstName(address.firstName ?? '');
                        setLastName(address.lastName ?? '');
                        setCompany(address.company ?? '');
                        setStreetAddress(address.streetAddress ?? '');
                        setStreetAddress2(address.streetAddress2 ?? '');
                        setSuburb(address.suburb ?? '');
                        setState(address.state ?? '');
                        setPostcode(address.postcode ?? '');
                        setCountry(address.country ?? '');
                        setPhone(address.phone ?? '');
                    }

                    setUserDetails(details);
                })
                .then(() => setLoading(false));
        }, 1200);
    }, []);

    const validate = () => {
        const errorList = [];
        validateEmail(errorList, email);
        mandatory(errorList, "Email", email);
        mandatory(errorList, "First Name", firstName);
        mandatory(errorList, "Last Name", lastName);
        mandatory(errorList, "Street Address", streetAddress);
        mandatory(errorList, "Suburb", suburb);
        mandatory(errorList, "State", state);
        mandatory(errorList, "Postcode", postcode);
        mandatory(errorList, "Country", country);
        mandatory(errorList, "Phone Number", phone);

        if (!recaptchaRef.current.getValue()) {
            errorList.push('Invalid reCAPTCHA. Please reload the page and try again.')
        }
        return errorList;
    }

    const placeOrder = async () => {
        setPlacing(true);

        const validationErrors = validate();
        setErrors(validationErrors);

        if (validationErrors && validationErrors.length > 0) {
            window.scrollTo(0, 0);
        }
        else {
            try {
                await post({ email, firstName, lastName, company, streetAddress, streetAddress2, suburb, state, postcode, country, phone, items: cart.items, user, saveShipping, shippingItem });
    
                if (!response.ok) {
                    setErrors(typeof response.data === 'string' ? [response.data] : [...response.data]);
                    window.scrollTo(0, 0);
                } 
                else {
                    openSnackbar(`Successfully placed order #${response.data.orderNumber}`);
                    dispatch(clear());
                    navigate("/", { replace: true });
                }
            }
            catch (e) {
                setErrors([e.message]);
                window.scrollTo(0, 0);
            }
        }

        setPlacing(false);
    }

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <Navbar/>

            <Helmet> 
                <title>Dabxlabs — Checkout</title>
            </Helmet>

            <div className='container mx-auto justify-center items-center flex flex-col'>
                <h1 className="text-5xl font-hero font-bold">Checkout</h1>

                { loading && <div className="mt-12"><Loading/></div> }

                { errors.length > 0 &&
                    <Callout
                        className="min-h-12 w-full mt-4"
                        title="Errors"
                        icon={ExclamationIcon}
                        color="rose"
                    >
                        { errors.map((error) => (<>{error} <br/></>))}
                    </Callout>
                }

                { !loading &&
                <div className="mt-6 bg-neutral-100 p-6 rounded-lg w-full text-black">
                    <Grid numCols={1} numColsLg={2} className="gap-4">
                        <Col>
                            <Card>
                                <div className="text-black">
                                    <h1 className="text-xl font-semibold">Contact Information</h1>

                                    <div className="mt-4">
                                        <label className="text-xs">Email Address</label>
                                        <TextInput disabled={user != null} className="mt-1" placeholder="example@domain.com" type="email" value={email} onChange={(e) => setEmail(e.target.value)}/> 
                                    </div>
                                </div>
                            </Card>

                            <Card className="mt-6">
                                <div className="text-black">
                                    <h1 className="text-xl font-semibold">Shipping Address</h1>

                                    <Grid numCols={1} numColsSm={1} numColsMd={2} className="mt-2 gap-2">
                                        <Col>
                                            <label className="text-xs">First Name</label>
                                            <TextInput className="mt-1" placeholder="First Name" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)}/> 
                                        </Col>

                                        <Col>
                                            <label className="text-xs">Last Name</label>
                                            <TextInput className="mt-1" placeholder="Last Name" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)}/> 
                                        </Col>
                                    </Grid>

                                    <Grid className="mt-2">
                                        <Col>
                                            <label className="text-xs">Company</label>
                                            <TextInput className="mt-1" placeholder="Company (optional)" type="text" value={company} onChange={(e) => setCompany(e.target.value)}/> 
                                        </Col>
                                    </Grid>

                                    <Grid className="mt-2">
                                        <Col>
                                            <label className="text-xs">Street Address</label>
                                            <TextInput className="mt-1" placeholder="Street Address" type="text" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)}/> 
                                        </Col>
                                    </Grid>

                                    <Grid className="mt-2">
                                        <Col>
                                            <TextInput className="mt-1" placeholder="Apartment, suite, unit, etc. (optional)" type="text" value={streetAddress2} onChange={(e) => setStreetAddress2(e.target.value)}/> 
                                        </Col>
                                    </Grid>

                                    <Grid className="mt-2">
                                        <Col>
                                            <label className="text-xs">Suburb</label>
                                            <TextInput className="mt-1" placeholder="Suburb" type="text" value={suburb} onChange={(e) => setSuburb(e.target.value)}/> 
                                        </Col>
                                    </Grid>

                                    <Grid numCols={1} numColsSm={1} numColsMd={3} className="mt-2 gap-2">
                                        <Col numColSpan={1}>
                                            <label className="text-xs">State</label>
                                            <TextInput className="mt-1" placeholder="State" type="text" value={state} onChange={(e) => setState(e.target.value)}/> 
                                        </Col>

                                        <Col numColSpan={2}>
                                            <label className="text-xs">Postcode</label>
                                            <TextInput className="mt-1" placeholder="Postcode" type="number" value={postcode} onChange={(e) => setPostcode(e.target.value)}/> 
                                        </Col>
                                    </Grid>

                                    <Grid className="mt-2">
                                        <Col>
                                            <label className="text-xs">Country</label>
                                            <TextInput className="mt-1" placeholder="Country" type="text" value={country} onChange={(e) => setCountry(e.target.value)}/> 
                                        </Col>
                                    </Grid>

                                    <Grid className="mt-2">
                                        <Col>
                                            <label className="text-xs">Phone Number</label>
                                            <TextInput className="mt-1" placeholder="Phone Number" type="tel" value={phone} onChange={(e) => setPhone(e.target.value)}/> 
                                        </Col>
                                    </Grid>

                                    { userDetails &&
                                        <Grid className="mt-4">
                                            <Col>
                                                <div className="flex align-center">
                                                    <input id="saveAddress" type="checkbox" defaultChecked={saveShipping} onChange={() => setSaveShipping((state) => !state)}></input>
                                                    <label htmlFor="saveAddress" className="text-xs ml-2">Save shipping address to your account for future purchases?</label>
                                                </div>
                                            </Col>
                                        </Grid>
                                    }
                                </div>
                            </Card>

                            <Card className="mt-6">
                                <div className="text-black">
                                    <h1 className="text-xl font-semibold">Shipping Method</h1>

                                    <Card className="mt-4">
                                        { shippings.map((shipping) => (
                                            <>
                                                <div className="flex flex-row justify-between">
                                                    <div className="flex flex-row gap-4">
                                                        <input checked={selectedShipping === shipping.id} onChange={() => setShipping(shipping.id)} id={shipping.id} value={shipping.id} type="radio" name="shipping"/>
                                                        <label htmlFor={shipping.id}>{shipping.name}</label>
                                                    </div>

                                                    <div className="font-semibold">
                                                        ${shipping.price.toFixed(2)} AUD
                                                    </div>
                                                </div>

                                                <div className="mt-1 text-neutral-400 font-normal">
                                                    <label htmlFor={shipping.id}>{shipping.estimated}</label>
                                                </div>

                                                { !(shippings.slice(-1)[0].id === shipping.id) && <Divider/> }
                                            </>
                                        ))}
                                    </Card>
                                </div>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                { cart.items.length > 0 && 
                                    <>
                                        { cart.items.map((item) => 
                                        <>
                                            <div className="flex flex-row items-center gap-6">
                                                <div className="w-22 h-22">
                                                    <div className="w-24 h-24 bg-black rounded-lg flex align-center">
                                                        <img className="h-full w-full object-cover rounded-lg" alt={item.name} src={ImageHelper.getMainImage(item.images).src}/>
                                                    </div>
                                                </div>

                                                <div>
                                                    <h6 className="font-semibold mb-4">{item.name}</h6>
                                                    <p className="text-neutral-500 mb-4 line-clamp-3">{item.description}</p>
                                                    <div className="flex text-md gap-3">
                                                        <h2 className={`font-semibold ${item.salePrice && item.salePrice > 0 ? 'flex' : 'hidden'}`}>${parseInt(item.salePrice).toFixed(2)} AUD</h2>
                                                        <h2 className={`font-semibold ${item.salePrice && item.salePrice > 0 && 'line-through text-neutral-500'}`}>${parseInt(item.price).toFixed(2)} AUD</h2>

                                                        x {item.quantity}
                                                    </div>
                                                </div>
                                            </div>
                                            { <Divider/> }
                                        </>
                                        )}
                                    </>
                                }

                                <div className="flex flex-row justify-between">
                                    <h4 className="text-neutral-500">Subtotal</h4>
                                    <h5 className="text-neutral-800 font-semibold">${subtotal.toFixed(2)}</h5>
                                </div>

                                <div className="flex flex-row justify-between">
                                    <h4 className="text-neutral-500">Shipping</h4>
                                    <h5 className="text-neutral-800 font-semibold">${shippingCost.toFixed(2)}</h5>
                                </div>

                                <Divider/>

                                <div className="flex flex-row justify-between">
                                    <h4 className="text-neutral-500">Total</h4>

                                    <div className="flex flex-row justify-center items-center">
                                        <h5 className=" text-neutral-400 mr-2">AUD</h5>
                                        <h5 className="text-2xl text-neutral-800 font-semibold">${total.toFixed(2)}</h5>
                                    </div>
                                </div>
                            </Card>

                            <Card className="mt-6 text-sm">
                                <Callout title="Direct Bank Transfer / EFT" className="mt-2" color="gray">
                                    Once you place your order, you will receive bank transfer instructions and account information. <br/>
                                    After payment is received, your order will be approved, processed & dispatched.
                                </Callout>

                                <Card className="mt-4 mb-4">
                                    <div className="flex flex-row justify-between">
                                        <h5>Contact Information</h5>

                                        <h4>{email}</h4>
                                    </div>

                                    <Divider/>

                                    <div className="flex flex-row justify-between">
                                        <h5>Shipping to</h5>
                                        <h4>{streetAddress} {streetAddress2} {suburb} {postcode} {state} {country}</h4>
                                    </div>

                                    <div className="flex flex-row justify-between mt-2">
                                        <div/>
                                        <h4>{shippingItem.name}</h4>
                                    </div>
                                </Card>

                                <div className="w-full flex mb-4 justify-between">
                                    <div/>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LfHtVQhAAAAAICt3fWmxWQ5AV6OkesuU9TMDzhv"
                                    />
                                </div>

                                <p className="text-neutral-400 text-xs">Your information and personal data will be used to process your order. By placing your order, you agree to allow us to use your data as per our privacy policy.</p>

                                <div className="flex flex-row justify-between mt-4">
                                    <div/>
                                    <Button loading={checkingOut} onClick={placeOrder} color="green" size="xl">Place Order</Button>
                                </div>
                            </Card>
                        </Col>
                    </Grid>
                </div>
                }
            </div>

            <Footer/>
        </motion.div>
    )
}

export default Checkout;