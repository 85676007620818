import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCart, addItem, removeItem, clear } from './CartSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'

const CartIcon = () => {
    const cart = useSelector(selectCart);
    const itemsCount = cart.items.length;
    const itemDisplay = itemsCount > 9 ? '9+' : itemsCount;

    return (
        <div className="group relative p-4 inline-block min-w-fit">
            { itemsCount > 0 && 
            <div>
                <div className="animate-ping text-sm absolute w-6 right-0 top-0 rounded-full bg-red-500 flex items-center justify-center font-mono opacity-75">.</div>
                <div className="absolute w-6 text-sm right-0 top-0 font-semibold rounded-full bg-red-500 flex items-center justify-center font-mono">{itemDisplay}</div>
            </div>
            }

            <FontAwesomeIcon className="text-2xl group-hover:text-zinc-400 group-hover:fill-zinc-300 transition ease-in-out duration-300" icon={faCartShopping} />
        </div>
    );
}

export default CartIcon