import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: []
  },
  reducers: {
    addItem: (state, action) => {
      const existingItem = state.items.find(item => item.id === action.payload.id);

      if (existingItem) {
        existingItem.quantity++;
      }
      else {
        const newItem = action.payload;
        newItem.quantity = 1;

        state.items.push(action.payload);
      }
    },
    updateQuantity: (state, action) => {
      const existingItem = state.items.find(item => item.id === action.payload.item.id);
      existingItem.quantity = parseFloat(action.payload.quantity);
    },
    removeItem: (state, action) => {
      state.items = state.items.filter(item => item.uniqueID !== action.payload.uniqueID);
    },
    clear: (state) => {
      state.items = [];
    },
  },
});

export const { addItem, removeItem, clear, updateQuantity } = cartSlice.actions;
export const selectCart = (state) => state.cartReducer;
export default cartSlice.reducer;
