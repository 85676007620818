import { useEffect, useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "@firebase/auth";
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/navbar/Footer';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { ReactComponent as Loading }  from '../img/loadingicon.svg';
import { useNavigate } from "react-router-dom"
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch, useSelector } from 'react-redux';
import { login, selectUser } from '../components/auth/UserSlice';
import { Button, Callout, Card, Divider, TextInput } from '@tremor/react';
import { ExclamationIcon } from '@heroicons/react/solid';

const Register = () => {
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const auth = getAuth();
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [openSnackbar] = useSnackbar({ position: 'top-center', style: { backgroundColor: '#22c55e', color: 'white', fontWeight: 'bold' }});
    const [verify, setVerify] = useState(false);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [newUser, setNewUser] = useState(null);

    const mandatory = (errors, text, value) => {
        if (!value || value === "") {
            errors.push(`A value for ${text} is required.`);
        }
    };

    useEffect(() => {
        if (user != null) {
            navigate("/account/profile", { replace: true });
        }
    }, []);

    const resend = async () => {
        setResendDisabled(true);

        try {
            await sendEmailVerification(newUser);
        }
        catch (e) {
            console.error(e);
        }

        setResendDisabled(false);
    };

    const submitLoginForm = () => {
        setErrors([])
        const errors = [];

        mandatory(errors, "Email", email);
        mandatory(errors, "Password", password);

        setErrors(errors);
        setLoading(true);

        if (errors.length === 0) {
            return createUserWithEmailAndPassword(auth, email, password)
                .then((payload) => {
                    const newUser = payload.user;
                    dispatch(login(newUser));

                    return sendEmailVerification(newUser, { url: window.location.origin + "/account/profile" }).then(() => {
                        openSnackbar('Successfully created a new account.');
                        dispatch(login(newUser));
                        setLoading(false);
                        setVerify(true);
                        setNewUser(newUser);
                    });
                })
                .catch((error) => {
                    if (error.code === 'auth/email-already-in-use') {
                        setErrors(['Email is already in use. Try a different email or login.']);                        
                    }
                    else if (error.code === 'auth/weak-password') {
                        setErrors(['Password should be at least 6 characters']);                        
                    }
                    else if (error.code === 'auth/invalid-email') {
                        setErrors(['Invalid email. Please provide a valid email.']);                        
                    }
                    else {
                        setErrors(['Something went wrong']);
                    }

                    console.error(error);
                    setLoading(false);
                })
        }
        else {
            setLoading(false);
        }
    };

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <Navbar/>

            {   !verify &&
                <>
                    <Helmet>
                        <title>Dabxlabs — Register</title>
                        <meta name="robots" content="noindex" />
                    </Helmet>

                    <div className='container mx-auto justify-center items-center flex flex-col'>
                        <div className="flex justify-center items-center flex-col w-full max-w-[500px]">
                            { errors.length > 0 &&
                            <Callout
                                className="min-h-12 w-full mt-4 mb-4"
                                title="Errors"
                                icon={ExclamationIcon}
                                color="rose"
                            >
                                { errors.map((error) => (<>{error} <br/></>))}
                            </Callout>
                            }

                            <Card className="w-full text-black">
                                <h5 className="text-2xl font-bold mb-4">Register</h5>
                                <Divider/>

                                <div>
                                    <label className="text-xs">Email Address</label>
                                    <TextInput className="mt-1 h-12" placeholder="example@domain.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>

                                <div className="mt-4">
                                    <label className="text-xs">Password</label>
                                    <TextInput className="mt-1 h-12" type="password" placeholder="*********" value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>

                                <Button size="xl" className="mt-4 float-right pl-12 pr-12" color="black" onClick={submitLoginForm} loading={loading}>
                                    Register
                                </Button>
                            </Card>
                        </div>
                    </div>
                </>
            }

            {
                verify &&
                <>
                    <Helmet>
                        <title>Dabxlabs — Verify Email</title>
                        <meta name="robots" content="noindex" />
                    </Helmet>

                    <div className='container mx-auto justify-center items-center flex flex-col'>
                        <div className="flex justify-center items-center flex-col w-full max-w-[500px]">
                            <Card className="w-full text-black flex items-center justify-center flex-col text-center">
                                <h2 className="text-xl font-semibold mb-4">Verify your email address</h2>

                                <p>
                                    A verification email has been sent to your email address. Follow the instructions in the email to verify your account.
                                </p>

                                <Button onClick={resend} disabled={resendDisabled} color="black" className="mt-12">Resend Email</Button>
                            </Card>
                        </div>
                    </div>
                </>
            }

            <Footer/>
        </motion.div>
    )
}

export default Register;