class ImageHelper {
    getMainImage(images) {
        const main = images.find((x) => x.type === "main");

        if (main) {
            return main;
        }
        
        const secondary = images.find((x) => x.type === "secondary");

        if (secondary) {
            return secondary;
        }

        return images[0] ?? { src: '' };
    }

    getSecondaryImage(images) {
        const secondary = images.find((x) => x.type === "secondary");

        if (secondary) {
            return secondary;
        }
        
        const main = images.find((x) => x.type === "main");

        if (main) {
            return main;
        }

        return images[0] ?? { src: '' };
    }
}

export default new ImageHelper();