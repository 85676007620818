import CartList from '../components/cart/CartList';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/navbar/Footer';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

const Cart = () => {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <Navbar/>

            <Helmet>
                <title>Dabxlabs — Cart</title>
            </Helmet>


            <div className='container mx-auto justify-center items-center flex flex-col'>
                <h1 className="text-5xl font-hero font-bold">Cart</h1>

                <CartList/>
            </div>

            <Footer/>
        </motion.div>
    )
}

export default Cart;