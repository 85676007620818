import { useState, useRef } from 'react';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/navbar/Footer';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { ReactComponent as Loading }  from '../img/loadingicon.svg';
import { useFetch } from 'use-http';
import { useNavigate } from "react-router-dom"
import { useSnackbar } from "react-simple-snackbar";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { Button, Callout, Card, Col, Divider, Grid, TextInput } from '@tremor/react';
import { ExclamationIcon } from '@heroicons/react/solid';

const Contact = () => {
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");
    const recaptchaRef = useRef();
    const navigate = useNavigate();
    const [openSnackbar] = useSnackbar({ position: 'top-center', style: { backgroundColor: '#22c55e', color: 'white', fontWeight: 'bold' }});

    const { post } = useFetch('https://us-central1-dabxlabs.cloudfunctions.net/contact');

    const mandatory = (errors, text, value) => {
        if (!value || value === "") {
            errors.push(`A value for ${text} is required.`);
        }
    };

    const validateEmail = (errors, email) => {
        const isValid = String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );

        if (!isValid) {
            errors.push('Please provide a valid email so we can get back to you.');
        }
      };

    const submitContactForm = () => {
        setErrors([])
        const errors = [];

        mandatory(errors, "First Name", firstName);
        mandatory(errors, "Last Name", lastName);
        mandatory(errors, "Email", email);
        mandatory(errors, "Phone Number", phoneNumber);
        mandatory(errors, "Message", message);
        validateEmail(errors, email);

        if (!recaptchaRef.current.getValue()) {
            errors.push('Invalid reCAPTCHA. Please reload the page and try again.')
        }

        setErrors(errors);

        setLoading(true);

        if (errors.length === 0) {
            return post({ firstName, lastName, email, phoneNumber, message })
                .then(() => {
                    setLoading(false);
                    openSnackbar('Contact message sent!');
                    navigate("/");
                });
        }
        else {
            setLoading(false);
        }
    };

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <Navbar/>

            <Helmet>
                <title>Dabxlabs — Contact</title>
                <meta name="description" content="The DabxLabs team has come together with a mission to provide you with the best customer service, fastest turnaround and most importantly the highest quality glassware and quartz without breaking the bank." />
            </Helmet>

            <div className='container mx-auto justify-center items-center flex flex-col'>
                <h1 className="text-6xl font-hero font-bold">Contact</h1>

                <div className="mt-12 mb-12 flex justify-center items-center flex-col md:min-w-[800px] max-w-[800px]">
                    <div className="w-full max-w-[800px]">
                        { errors.length > 0 &&
                        <Callout
                            className="min-h-12 w-full mt-4 mb-4"
                            title="Errors"
                            icon={ExclamationIcon}
                            color="rose"
                        >
                            { errors.map((error) => (<>{error} <br/></>))}
                        </Callout>
                        }

                        <Card className="w-full text-black">
                            <h5 className="text-2xl font-bold mb-4">Contact</h5>
                            <Divider/>

                            <Grid numCols={1} className="gap-4">
                                <Col>
                                    <Grid numCols={2} className="gap-4">
                                        <div>
                                            <label className="text-xs">First Name</label>
                                            <TextInput className="mt-1 h-12" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                        </div> 

                                        <div>
                                            <label className="text-xs">Last Name</label>
                                            <TextInput className="mt-1 h-12" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                        </div> 
                                    </Grid>
                                </Col>

                                <Col className="gap-4 flex flex-col">
                                    <div>
                                        <label className="text-xs">Email Address</label>
                                        <TextInput className="mt-1 h-12" type="email" placeholder="example@domain.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div> 

                                    <div>
                                        <label className="text-xs">Phone Number</label>
                                        <TextInput className="mt-1 h-12" type="tel" placeholder="0400 000 000" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                    </div>
                                </Col>

                                <Col>
                                    <label className="block text-neutral-600 text-sm font-bold mb-2">Message</label>
                                    <textarea className="h-64 w-full p-8 border border-neutral-300 rounded-lg" placeholder="Your Message" value={message} onChange={(e) => setMessage(e.target.value)}/>
                                </Col>

                                <Col>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LfHtVQhAAAAAICt3fWmxWQ5AV6OkesuU9TMDzhv"
                                    />
                                </Col>

                                <Col>
                                    <Button color="black" size="xl" className="w-full" loading={loading} onClick={submitContactForm}>Send</Button>
                                </Col>
                            </Grid>
                        </Card>
                    </div>
                </div>
            </div>

            <Footer/>
        </motion.div>
    )
}

export default Contact;