import { useNavigate } from "react-router-dom"
import { useEffect } from 'react';
import { clear } from '../components/cart/CartSlice';
import { useDispatch } from 'react-redux';
import { useSnackbar } from "react-simple-snackbar";

const Success = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openSnackbar] = useSnackbar({ position: 'top-center', style: { backgroundColor: '#22c55e', color: 'white', fontWeight: 'bold' }});

    useEffect(() => {
        dispatch(clear());
        openSnackbar('Order placed!');
        navigate("/");
    }, []);

    return (<div/>);
};

export default Success;