import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null
  },
  reducers: {
    login: (state, action) => {
        state.user = { ...action.payload.user };

        if (state.user.email === "dion.misic@gmail.com" || state.user.email === "dabxlabs@protonmail.com") {
          state.user.admin = true;
        }
    },
    logout: (state, action) => {
        state.user = null;
    },
  },
});

export const { login, logout } = userSlice.actions;
export const selectUser = (state) => {
    return state.userReducer.user;
}
export default userSlice.reducer;
