import { useState } from 'react';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "@firebase/auth";
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/navbar/Footer';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { useNavigate } from "react-router-dom"
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch } from 'react-redux';
import { login } from '../components/auth/UserSlice';
import { Button, Callout, Card, Divider, TextInput } from '@tremor/react';
import { ExclamationIcon } from '@heroicons/react/solid';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = getAuth();
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [openSnackbar] = useSnackbar({ position: 'top-center', style: { backgroundColor: '#22c55e', color: 'white', fontWeight: 'bold' }});

    const mandatory = (errors, text, value) => {
        if (!value || value === "") {
            errors.push(`A value for ${text} is required.`);
        }
    };

    const submitLoginForm = () => {
        setErrors([])
        const errors = [];

        mandatory(errors, "Email", email);
        mandatory(errors, "Password", password);

        setErrors(errors);
        setLoading(true);

        if (errors.length === 0) {
            return signInWithEmailAndPassword(auth, email, password)
                .then((user) => {
                    openSnackbar('Successfully logged in.');
                    setLoading(false);
                    navigate("/account/profile", { replace: true });

                    return dispatch(login(user));
                })
                .catch((error) => {
                    console.error(error);
                    setErrors(['Something went wrong. We were unable to verify your credentials.']);
                    setLoading(false);
                });
        }
        else {
            setLoading(false);
        }
    };

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <Navbar/>

            <Helmet>
                <title>Dabxlabs — Login</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <div className='container mx-auto justify-center items-center flex flex-col'>
                <div className="flex justify-center items-center flex-col w-full max-w-[500px]">
                    { errors.length > 0 &&
                    <Callout
                        className="min-h-12 w-full mt-4 mb-4"
                        title="Errors"
                        icon={ExclamationIcon}
                        color="rose"
                    >
                        { errors.map((error) => (<>{error} <br/></>))}
                    </Callout>
                    }

                    <Card className="w-full text-black">
                        <h5 className="text-2xl font-bold mb-4">Login</h5>
                        <Divider/>

                        <div>
                            <label className="text-xs">Email Address</label>
                            <TextInput className="mt-1 h-12" placeholder="example@domain.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <div className="mt-4">
                            <label className="text-xs">Password</label>
                            <TextInput className="mt-1 h-12" type="password" placeholder="*********" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>

                        <Button size="xl" className="mt-4 float-right pl-12 pr-12" color="black" onClick={submitLoginForm} loading={loading}>
                            Login
                        </Button>
                    </Card>
                </div>
            </div>

            <Footer/>
        </motion.div>
    )
}

export default Login;