import Navbar from '../components/navbar/Navbar';
import Footer from '../components/navbar/Footer';
import { Helmet } from 'react-helmet';

const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>Dabxlabs — Not Found</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <Navbar/>

            <div className='container mx-auto justify-center items-center flex flex-col mt-32 mb-32'>
                <h1 className="text-5xl font-hero font-bold">404 — Not Found.</h1>
            </div>

            <Footer/>
        </>
    )
}

export default NotFound;