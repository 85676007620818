import { firestore } from '../firebase';
import { getDocs, query, collection, where, getDoc, doc } from '@firebase/firestore';

class FirestoreHelper {
    async getFeatured() {
        return this.formatDocs(query(collection(firestore, "products"), where("featured", "==", true)));
    } 

    async getAll() {
        return this.formatDocs(query(collection(firestore, "products")));
    }

    async getAllOrders(user) {
        if (user) {
            return this.formatDocs(query(collection(firestore, "orders"), where("email", "==", user.email)));
        }
        else {
            return this.formatDocs(query(collection(firestore, "orders")));
        }
    }

    async getByCategory(category) {
        return this.formatDocs(query(collection(firestore, "products"), where("category", "==", category)));
    }

    async getItemById(id) {
        return (await getDoc(query(doc(firestore, "products", id)))).data();
    }

    async getUserDetails(user) {
        if (user) {
            const result = await this.formatDocs(query(collection(firestore, "users"), where("uid", "==", user.uid)));
            return result[0] ?? null;
        }

        return null;
    }

    async formatDocs(query) {
        const snapshot = await getDocs(query);
        const items = [];
        snapshot.forEach((doc) => items.push({ id: doc.id, ...doc.data()}));
        return items;
    }
}

export default new FirestoreHelper();