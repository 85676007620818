import Navbar from '../components/navbar/Navbar';
import Footer from '../components/navbar/Footer';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { selectUser } from '../components/auth/UserSlice';
import { ArrowLeftIcon, CurrencyDollarIcon, ExclamationIcon, TicketIcon } from "@heroicons/react/solid";
import { useSnackbar } from 'react-simple-snackbar';
import { useEffect, useState } from 'react';
import { ReactComponent as Loading }  from '../img/loadingicon.svg';
import { 
    Grid, 
    Tab, 
    TabList, 
    Card,
    Table,
    TableHead,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    Text,
    Title,
    Badge,
    Dropdown,
    DropdownItem,
    Button,
    TextInput,
    Toggle,
    ToggleItem,
    Col,
    Callout,
    Flex,
    Icon,
    Metric,
    Divider,
} from "@tremor/react";
import { useLocation, useNavigate } from 'react-router-dom';
import FirestoreHelper from '../components/FirestoreHelper';
import CategoryHelper from '../components/CategoryHelper';
import { addDoc, collection, deleteDoc, doc, updateDoc } from '@firebase/firestore';
import { firestore } from '../firebase';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from '@firebase/storage';
import ImageHelper from '../components/ImageHelper';
import EditableOrder from '../components/EditableOrder';

const Admin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(selectUser);
    const [selectedView, setSelectedView] = useState(location.state?.view ?? "2");
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [orders, setOrders] = useState([]);
    const [editingItem, setEditingItem] = useState(location.state?.item);
    const [dropdownVal, setDropdownVal] = useState(null);

    useEffect(() => {
        if (user == null) {
            navigate("/", { replace: true });
        }
        else if (!user.admin) {
            navigate("/account/profile", { replace: true });
        }
        else {
            FirestoreHelper
                .getAll()
                .then((allItems) => setItems(allItems))
                .then(() => FirestoreHelper.getAllOrders().then((allOrders) => setOrders(allOrders)))
                .finally(setLoading(false));
        }
    }, [navigate, user]);

    return (
        <>
            <Helmet>
                <title>Dabxlabs — Admin Dashboard</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <Navbar/>

            {loading && 
            <div className='container mx-auto mt-16 mb-32'>
                <Loading/>
            </div>
            }

            { !loading &&
            <div className='container mx-auto mt-16 mb-32'>
                <h1 className="text-5xl font-hero font-bold">Admin Dashboard</h1>

                {
                    (selectedView === "1" || selectedView === "2" || selectedView === "3") &&
                    <TabList
                        color="white"
                        value={selectedView}
                        onValueChange={(value) => { 
                            navigate('/account/admin', { state: { view: value, item: null }});
                            setSelectedView(value);
                        }}
                        className="mt-6"
                    >
                        <Tab value="2" text="Products" />
                        <Tab value="3" text="Orders" />
                    </TabList>
                }

                { selectedView === "1" && (
                    <div className="mt-6 bg-neutral-200 p-6 rounded-lg">
                        <Grid numColsSm={2} numColsLg={3} className="gap-6">
                            <Card key="Total Sales" decoration="top" decorationColor="indigo">
                                <Flex justifyContent="start" className="space-x-4">
                                    <Icon
                                        icon={TicketIcon}
                                        variant="light"
                                        size="xl"
                                        color="indigo"
                                    />
                                    <div className="truncate">
                                        <Text>Total Sales</Text>
                                        <Metric className="truncate">500</Metric>
                                    </div>
                                </Flex>
                            </Card>
                        </Grid>

                        <div className="mt-6">
                            <Card>
                            <div className="h-80" />
                            </Card>
                        </div>
                    </div>
                )}

                { selectedView === "2" && (
                    <>
                        <Card className="mt-6 min-h-[800px]">
                            <div className="flex flex-row justify-between items-center">
                                <h1 className="text-black text-lg font-bold">Products</h1>
                                <Button 
                                    onClick={() => {
                                        setSelectedView("NEW");
                                        setEditingItem({ name: '', description: '', category: 'dab-rigs', featured: false, price: 0, salePrice: 0, stock: 0, units_sold: 0, images: [] });
                                    }}
                                    color="green">
                                    + Add New
                                </Button>
                            </div>

                            <Table className="mt-6 mb-[50px] min-h-[800px]">
                                <TableHead>
                                    <TableRow>
                                        <TableHeaderCell>Actions</TableHeaderCell>
                                        <TableHeaderCell>Name</TableHeaderCell>
                                        <TableHeaderCell>Category</TableHeaderCell>
                                        <TableHeaderCell>Price ($AUD)</TableHeaderCell>
                                        <TableHeaderCell>Stock</TableHeaderCell>
                                        <TableHeaderCell>Units Sold</TableHeaderCell>
                                        <TableHeaderCell>Featured</TableHeaderCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.sort((a, b) => a.name.localeCompare(b.name)).map((item) => (
                                        <TableRow key={item.name}>
                                              <TableCell>
                                                <Dropdown
                                                    className="mt-2 min-w-[16px]"
                                                    value={dropdownVal}
                                                    onValueChange={(value) => {
                                                        if (value === "DEL") {
                                                            if (window.confirm("Are you sure you want to delete this record? It will be unrecoverable.") === true) {
                                                                const document = doc(firestore, "products", item.id);                                                
                                                                return deleteDoc(document).then(() => {
                                                                    const itemsCopy = [...items];
                                                                    setItems(itemsCopy.filter((x) => x.id !== item.id));
                                                                });
                                                            } else {
                                                                return;
                                                            }
                                                        }
                                                        else if (value === "EDT") {
                                                            setSelectedView("EDT");
                                                            setEditingItem(item);
                                                            navigate('/account/admin', {state: { view: "EDT", item }});
                                                        }
                                                    }}
                                                    placeholder="Actions"
                                                >
                                                    <DropdownItem className="text-blue-500 font-semibold" value="EDT" text="Edit" />
                                                    <DropdownItem className="text-red-500 font-semibold" value="DEL" text="Delete" />
                                                </Dropdown>
                                            </TableCell>
                                            <TableCell className="flex items-center">
                                                <div className="w-24 h-24 bg-black mr-6 rounded-lg flex align-center">
                                                    <img className="h-full w-full object-cover rounded-lg" alt={item.name} src={ImageHelper.getMainImage(item.images).src}/>
                                                </div>

                                                <Text className="inline">{item.name}</Text>
                                            </TableCell>
                                            <TableCell>
                                                <Badge>
                                                    {CategoryHelper.getCategoryTitle(item.category)}
                                                </Badge>
                                            </TableCell>
                                            <TableCell>${item.price} AUD</TableCell>
                                            <TableCell>{item.stock}</TableCell>
                                            <TableCell>{item.units_sold}</TableCell>
                                            <TableCell>{item.featured ? "Yes" : "No"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Card>
                    </>
                )}

                { selectedView === "3" && (
                    <>
                    <Card className="mt-6 min-h-[800px]">
                        <div className="flex flex-row justify-between items-center">
                            <h1 className="text-black text-lg font-bold">Orders</h1>
                        </div>

                        <Table className="mt-6 mb-[50px] min-h-[800px]">
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell>Actions</TableHeaderCell>
                                    <TableHeaderCell>Order #</TableHeaderCell>
                                    <TableHeaderCell>Status</TableHeaderCell>
                                    <TableHeaderCell>Customer</TableHeaderCell>
                                    <TableHeaderCell>Total Cost</TableHeaderCell>
                                    <TableHeaderCell>Items</TableHeaderCell>
                                    <TableHeaderCell>Order Date</TableHeaderCell>
                                    <TableHeaderCell>Shipping Address</TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.sort((a, b) => getFirebaseDate(b.date).localeCompare(getFirebaseDate(a.date))).map((order) => (
                                    <TableRow key={order.orderNumber}>
                                          <TableCell>
                                            <Dropdown
                                                className="mt-2 min-w-[16px]"
                                                value={dropdownVal}
                                                onValueChange={(value) => {
                                                    if (value === "DEL") {
                                                        if (window.confirm("Are you sure you want to delete this record? It will be unrecoverable.") === true) {
                                                            const document = doc(firestore, "orders", order.id);                                                
                                                            return deleteDoc(document).then(() => {
                                                                const ordersCopy = [...orders];
                                                                setOrders(ordersCopy.filter((x) => x.id !== order.id));
                                                            });
                                                        } else {
                                                            return;
                                                        }
                                                    }
                                                    else if (value === "EDT-ORD") {
                                                        setSelectedView("EDT-ORD");
                                                        setEditingItem(order);
                                                        navigate('/account/admin', {state: { view: "EDT-ORD", item: order }});
                                                    }
                                                }}
                                                placeholder="Actions"
                                            >
                                                <DropdownItem className="text-blue-500 font-semibold" value="EDT-ORD" text="View Order" />
                                                <DropdownItem className="text-red-500 font-semibold" value="DEL" text="Delete" />
                                            </Dropdown>
                                        </TableCell>
                                        <TableCell>#{order.orderNumber}</TableCell>
                                        <TableCell><Badge className={`text-white font-semibold ${getStatusColor(order.status)}`}>{order.status}</Badge></TableCell>
                                        <TableCell>{order.firstName} {order.lastName} ({order.email})</TableCell>
                                        <TableCell>${order.items.map(x => parseFloat(x.salePrice && x.salePrice > 0 ? x.salePrice : x.price) * x.quantity).reduce((a, b) => a + b, 0)} AUD</TableCell>
                                        <TableCell>{order.items.map(x => x.quantity).reduce((a, b) => a + b, 0)}</TableCell>
                                        <TableCell>{getFirebaseDate(order.date)}</TableCell>
                                        <TableCell>{order.streetAddress} {order.streetAddress2} {order.suburb} {order.postcode} {order.state} {order.country}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Card>
                </>
                )}

                { 
                    ((selectedView === "EDT" || selectedView === "NEW") && editingItem) && 
                    <>
                        <EditableItem 
                            isCreating={selectedView === "NEW"} 
                            onClick={() => {
                                setSelectedView("2");
                                navigate('/account/admin', {state: { view: "2", item: null }})
                            }} 
                            onSave={(newItem) => {
                                const newItems = items.filter((x) => x.id !== newItem.id);
                                newItems.push(newItem);
                                setItems(newItems);

                                if (selectedView === "NEW") {
                                    setSelectedView("2");
                                    navigate('/account/admin', {state: { view: "2", item: null }});
                                }
                                else {
                                    navigate('/account/admin', {state: { view: "EDT", item: newItem }});
                                }
                            }} 
                            item={editingItem}/>
                    </>
                }

                {
                    (selectedView === "EDT-ORD" && editingItem) && 
                    <>
                        <EditableOrder
                            order={editingItem}
                            onClick={() => {
                                setSelectedView("3");
                                navigate('/account/admin', {state: { view: "3", item: null }})
                            }} 
                            onSave={(newItem) => {
                                const newItems = orders.filter((x) => x.id !== newItem.id);
                                newItems.push(newItem);
                                setOrders(newItems);

                                navigate('/account/admin', {state: { view: "3", item: newItem }});
                            }} 
                        />
                    </>
                }
            </div>
            }

            <Footer/>
        </>
    )
}

const mandatory = (errors, text, value) => {
    if (!value || value === "") {
        errors.push(`A value for ${text} is required.`);
    }
};

const EditableItem = (props) => {
    const { item, onClick, onSave, isCreating } = props;
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [tab, setTab] = useState("MTD");
    const [changed, setChanged] = useState(false);
    const [openSnackbar] = useSnackbar({ position: 'top-center', style: { backgroundColor: '#22c55e', color: 'white', fontWeight: 'bold' }});
    const [openErrorSnackbar] = useSnackbar({ position: 'top-center', style: { backgroundColor: 'red', color: 'white', fontWeight: 'bold' }});

    const [name, setName] = useState(item.name);
    const [description, setDescription] = useState(item.description);
    const [category, setCategory] = useState(item.category);
    const [price, setPrice] = useState(item.price);
    const [salePrice, setSalePrice] = useState(item.salePrice);
    const [stock, setStock] = useState(item.stock);
    const [featured, setFeatured] = useState(item.featured);
    const [images, setImages] = useState(item.images);
    const [errors, setErrors] = useState([]);

    const validate = () => {
        const newErrors = [];
        mandatory(newErrors, "Product Name", name);
        mandatory(newErrors, "Description", description);

        if (0 >= price) {
            newErrors.push("Price needs to be non negative and greater than zero.");
        }
    
        if (parseFloat(salePrice) > price) {
            newErrors.push("Sale Price needs to be less than normal/retail price.");
        }

        if (0 > stock) {
            newErrors.push("Stock needs to be non negative.");
        }

        setErrors(newErrors);
        return newErrors;
    }

    useEffect(() => {
        if (!loading) {
            setChanged(true);
        }
        else {
            setLoading(false);
        }
    }, [name, description, category, price, salePrice, stock, featured, images]);

    const onFileUpload = (e) => {
        setLoading(true);
        const file = e.target.files[0];
        const fileInMb = file.size / 1000000;

        if (fileInMb > 20) {
            openErrorSnackbar('File too big. Use one in a smaller size so it is better optimized for web');
            setLoading(null);
            e.target.value = null;
            return;
        }

        const newImage = { 
            isNew: true, 
            type: images.find((x) => x.type === "main")
                ? images.find((x) => x.type === "secondary")
                    ? "default"
                    : "secondary"
                : "main",
            file, 
            src: URL.createObjectURL(file) };
        const imagesCopy = [...images];
        imagesCopy.push(newImage);
        setImages(imagesCopy);
        setChanged(true);
    };

    const saveChanges = async () => {
        const newErrors = validate();

        if (newErrors.length > 0) {
            return;
        }

        setSaving(true);

        const newValues = {
            name,
            description,
            category,
            price: parseFloat(price),
            salePrice: parseFloat(salePrice),
            stock: parseFloat(stock),
            featured,
        }

        try {
            const imagesInDb = images.filter(x => !x.isNew && !x.toDelete);

            for (const image of images) {
                if (image.isNew) {
                    const storage = getStorage();
                    const storageName = 'products/' + name.toLowerCase().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/\s/g,'') + "-" + Math.random().toString().slice(2,11) + ".jpg";
                    const storageRef = ref(storage, storageName);
    
                    const snapshot = await uploadBytes(storageRef, image.file);
                    const downloadUrl = await getDownloadURL(snapshot.ref);
                    imagesInDb.push({ src: downloadUrl , type: image.type });
                }
                else if (image.toDelete) {
                    const storage = getStorage();
                    const storageRef = ref(storage, image.src); 
                    await deleteObject(storageRef);
                }
            }

            newValues.images = imagesInDb;

            if (isCreating) {
                const newDoc = await addDoc(collection(firestore, "products"), newValues);
                item.id = newDoc.id;
                openSnackbar('Successfully created product.');
            }
            else {
                const document = doc(firestore, "products", item.id);
                await updateDoc(document, newValues);
                openSnackbar('Successfully updated product.');
            }

            setChanged(false);
        }
        catch (e) {
            openErrorSnackbar(e);
        }

        setSaving(false);
        onSave({
            id: item.id,
            units_sold: item.units_sold,
            ...newValues
        });
    };

    return (
        <Card className="mt-6 text-black">
            <div>
                <Button onClick={onClick} variant="light" icon={ArrowLeftIcon} iconPosition="left">Back</Button>

                <div className="flex flex-row items-center justify-between">
                    <Title className="mt-6 text-2xl">{item.name}</Title>
                    <Button loading={saving} onClick={saveChanges} disabled={!changed} color="green">Save Changes</Button>
                </div>
            </div>

            { errors.length > 0 &&
                <Callout
                    className="min-h-12 mt-4"
                    title="Errors"
                    icon={ExclamationIcon}
                    color="rose"
                >
                    { errors.map((error) => (<>{error} <br/></>))}
                </Callout>
            }

            <TabList defaultValue="MTD" onValueChange={(val) => setTab(val)} className="mt-6">
                <Tab value="MTD" text="Metadata"/>
                <Tab value="IMG" text="Images"/>
            </TabList>

            { tab === "MTD" &&
                <div className="mt-6 min-h-[30vh]">
                    <Grid numCols={1} numColsSm={2} numColsLg={4} className="gap-2">
                    <Col numColSpan={1} numColSpanSm={2} numColSpanLg={4}>
                            <label className="block text-neutral-600 text-sm font-bold mb-2">Product Name</label>
                            <TextInput className="mb-6" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)}/> 
                        </Col>

                        <Col>
                            <label className="block text-neutral-600 text-sm font-bold mb-2">Category</label>
                            <Dropdown
                                className="mt-2"
                                onValueChange={(e) => setCategory(e)}
                                value={category}
                            >
                                {
                                    CategoryHelper.getAllCategories().map((category) => <DropdownItem value={category} text={CategoryHelper.getCategoryTitle(category)} />) 
                                }
                            </Dropdown>
                        </Col>

                        <Col>
                            <label className="block text-neutral-600 text-sm font-bold mb-2">Stock</label>
                            <TextInput type="number" className="mb-6" placeholder="Stock" value={stock} onChange={(e) => setStock(e.target.value)}/> 
                        </Col>

                        <Col>
                            <label className="block text-neutral-600 text-sm font-bold mb-2">Featured</label>
                            <Toggle
                                color="zinc"
                                value={featured}
                                onValueChange={(value) => setFeatured(value)}
                            >
                                <ToggleItem value={true} text="Featured" />
                                <ToggleItem value={false} text="Not Featured" />
                            </Toggle>
                        </Col>

                        <Col numColSpan={1} numColSpanSm={2} numColSpanLg={4} className="flex gap-2">
                            <div>
                                <label className="block text-neutral-600 text-sm font-bold mb-2">Price (AUD)</label>
                                <TextInput type="number" icon={CurrencyDollarIcon} className="mb-6" placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value)}/> 
                            </div>

                            <div>
                                <label className="block text-neutral-600 text-sm font-bold mb-2">Sale Price (AUD)</label>
                                <TextInput type="number" icon={CurrencyDollarIcon} className="mb-6" placeholder="Sale Price" value={salePrice} onChange={(e) => setSalePrice(e.target.value)}/> 
                            </div>
                        </Col>

                        <Col numColSpan={1} numColSpanSm={2} numColSpanLg={4}>
                            <label className="block text-neutral-600 text-sm font-bold mb-2">Description</label>
                            <textarea className="h-64 w-full p-8 border border-neutral-300 rounded-lg" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                        </Col>
                    </Grid>
                </div>
            }

            { tab === "IMG" &&
                <div className="flex flex-wrap min-h-[30vh]">
                    {
                        images.filter(x => !x.toDelete).map((img) => 
                            <div className="w-64 h-64 bg-neutral-100 flex justify-center items-center mt-6 mr-6 group relative">
                                <div className="group-hover:block hidden absolute m-1 right-0 top-0">
                                    <Dropdown
                                        className="mt-2"
                                        value={null}
                                        onValueChange={(value) => {
                                            let imagesCopy = [...images];
                                            const imageBeingEdited = imagesCopy.find((x) => x.src === img.src);

                                            if (value === "DEL") {
                                                if (window.confirm("Are you sure you want to delete this image? It will be unrecoverable.") === true) {
                                                    imageBeingEdited.toDelete = true;
                                                } else {
                                                    return;
                                                }
                                            }
                                            else if (value === "MAI") {
                                                imagesCopy.filter((x) => x.type === "main").forEach((x) => x.type = "default");
                                                imageBeingEdited.type = "main";
                                            }
                                            else if (value === "SCN") {
                                                imagesCopy.filter((x) => x.type === "secondary").forEach((x) => x.type = "default");
                                                imageBeingEdited.type = "secondary";
                                            }

                                            setImages(imagesCopy);
                                        }}
                                        placeholder="Edit"
                                    >
                                        <DropdownItem className="text-blue-500 font-semibold" value="MAI" text="Set as Default" />
                                        <DropdownItem className="text-blue-500 font-semibold" value="SCN" text="Set as Secondary" />
                                        <DropdownItem className="text-red-500 font-semibold" value="DEL" text="Delete" />
                                    </Dropdown>
                                </div>

                                { img.type === "main" && <Badge color="purple" className="absolute group-hover:hidden font-bold top-0 right-0 m-2">Default</Badge> }
                                { img.type === "secondary" && <Badge color="blue" className="absolute group-hover:hidden font-bold top-0 right-0 m-2">Secondary</Badge> }
                                <img className="h-full w-full object-cover" alt="Product" src={img.src}/>
                            </div>
                        )
                    }

                    <label htmlFor="dropzone">
                        <div className="w-64 h-64 bg-neutral-100 flex justify-center items-center hover:cursor-pointer hover:bg-neutral-200 mt-6 mr-6">
                            <h1 className="bold text-9xl mt-[-25px]">+</h1>
                        </div>
                    </label>
                    <input id="dropzone" onChange={onFileUpload} type="file" className="hidden" accept=".jpg, .jpeg, .png, .gif, .tif"/>
                </div>
            }
        </Card>
    )
}

export const getFirebaseDate = (d) => {
    const fireBaseTime = new Date(
        d.seconds * 1000 + d.nanoseconds / 1000000,
    );
    return fireBaseTime.toDateString() + " " + fireBaseTime.toLocaleTimeString();
};

export function getStatusColor(status) {
    switch (status) {
        case 'Fulfilled':
            return 'bg-green-900';
        case 'Out for Delivery':
            return 'bg-green-900';
        case 'Rejected':
            return 'bg-red-600';
        case 'Paid':
            return 'bg-green-600';
        default:
            return 'bg-neutral-400'
    }
}

export default Admin;