import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Cart from './pages/Cart';
import ProductPage from './pages/ProductPage';
import SmokeElement from "smoke-effect-react";
import AgeVerification from './components/age-verification/AgeVerification';
import { AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import ProductDetails from './pages/ProductDetails';
import NotFound from './pages/NotFound';
import Contact from './pages/Contact';
import Success from './pages/Success';
import Login from './pages/Login';
import Admin from './pages/Admin';
import Logout from './pages/Logout';
import About from './pages/About';
import Checkout from './pages/Checkout';
import Register from './pages/Register';
import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { useDispatch } from 'react-redux';
import { login, logout } from './components/auth/UserSlice';
import Profile from './pages/Profile';
import VerifyEmail from './pages/VerifyEmail';
import { useState } from 'react';

function App() {
  return (
    <BrowserRouter>
      <Helmet defaultTitle="DabxLabs — Official Website">
        <html lang="en" amp />
        <title>Dabxlabs — Official Website</title>
        <meta charSet="utf-8"/>
        <meta name="description" content="The official website of DabxLabs. The DabxLabs team has come together with a mission to provide you with the best customer service, fastest turnaround and most importantly the highest quality glassware and quartz without breaking the bank." />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
        <meta name="theme-color" content="#000000"/>
      </Helmet>

      <Root/>
    </BrowserRouter>
  );
}

function Root() {
  const auth = getAuth();
  const dispatch = useDispatch();
  const [authDone, setDone] = useState(false);

  onAuthStateChanged(auth, (userAuth) => {
    if (userAuth) {
      dispatch(login({ user: userAuth }));
    }
    else {
      dispatch(logout());
    }

    setDone(true);
  });

  return (
    <>
    {
      authDone &&
      <AnimatePresence>
        <AgeVerification>
          <Routes>
            <Route index path="/" element={<Home/>} />
            <Route path="about" element={<About/>} />
            <Route path="cart" element={<Cart/>} />
            <Route path="contact" element={<Contact/>} />
            <Route path="products/:path" element={<ProductPage/>}/>
            <Route path="product/:productId" element={<ProductDetails/>}/>
            <Route path="order/success" element={<Success/>} />
            <Route path="checkout" element={<Checkout/>} />
            <Route path="account/admin" element={<Admin/>} />
            <Route path="account/profile" element={<Profile/>} />
            <Route path="account/verify" element={<VerifyEmail/>} />
            <Route path="account/login" element={<Login/>} />
            <Route path="account/register" element={<Register/>} />
            <Route path="account/logout" element={<Logout/>} />
            <Route path="*" element={<NotFound/>}/>
          </Routes>

          <div className="smoke flex items-center fixed top-0 left-0 -z-50 w-screen h-screen overflow-hidden">
            <SmokeElement
                src=""
                opacity="0.2"
                smokeSrc="https://s3-us-west-2.amazonaws.com/s.cdpn.io/95637/Smoke-Element.png"
                smokeOpacity="0.2"
            />
          </div>
        </AgeVerification>
      </AnimatePresence>
    }
    </>
  )
}

export default App;