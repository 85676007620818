import Navbar from '../components/navbar/Navbar';
import Footer from '../components/navbar/Footer';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ReactComponent as Loading }  from '../img/loadingicon.svg'
import useFetch from 'use-http';
import Item from '../components/Item';
import CategoryHelper from '../components/CategoryHelper';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import FirestoreHelper from '../components/FirestoreHelper';

const ProductPage = (props) => {
    const { path } = useParams();
    const [title, setTitle] = useState("");
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTitle(CategoryHelper.getCategoryTitle(path));

        FirestoreHelper.getByCategory(path).then((items) => setItems(items)).finally(() => setLoading(false));
    }, [path]);

    return (
        <motion.div key={path} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <Helmet>
                <title>Dabxlabs — {title}</title>
            </Helmet>

            <Navbar/>

            <div className='container mx-auto'>
                <div className="mt-12 mb-12 border-b border-white pb-6 md:w-1/3">
                    <h1 className="antialiased text-5xl md:text-7xl font-hero font-bold tracking-tight">{title}</h1>
                </div>
            </div>

            <div className={`container mx-auto mt-12 mb-12 md:mt-24 md:mb-24 flex flex-wrap gap-14 ${path}`}>
                { loading && <Loading/>}

                { !loading && items.length > 0 && items.map((item) => <Item key={item.id} item={item}/>) }
                { !loading && items.length === 0 && <h5><div className="pt-24 pb-24"><h4 className='text-1xl'>No products could be found.</h4></div></h5> }
            </div>

            <Footer/>
        </motion.div>
    )
}

export default ProductPage;