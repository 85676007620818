import { useDispatch } from 'react-redux';
import { removeItem, updateQuantity } from './CartSlice';
import CategoryHelper from "../CategoryHelper";
import { useState } from "react";
import ImageHelper from "../ImageHelper";

const CartItem = (props) => {
    const dispatch = useDispatch();
    const { item } = props;

    const [quantity, setQuantity] = useState(parseInt(item.quantity));

    const removeFromCart = () => {
        return dispatch(removeItem(item));
    }

    const dispatchQuantity = (newVal) => {
        return dispatch(updateQuantity({ item, quantity: newVal }));
    }

    const onSale = item?.salePrice && item.salePrice > 0;

    return (
        <div className="flex flex-row flex-wrap sm:flex-nowrap pt-6 pb-6 align-center items-center w-full md:w-9/12">
            <div className="w-32 h-auto sm:w-64 sm:h-auto bg-black rounded-md mr-6 justify-start flex overflow-hidden">
                <img className="w-full object-cover" src={ImageHelper.getMainImage(item.images).src} alt="item"/>
            </div>

            <div className="flex flex-nowrap justify-center align-center items-center w-full md:w-9/12">
                <div className="pt-4 md:pt-0 pl-0 md:pl-4 w-full">
                    <div className="flex justify-between">
                        <div>
                            <h2 className="text-2xl font-bold">{item.name}</h2>
                            <h3 className="text-gray-300 italic">{CategoryHelper.getCategoryTitle(item.category)}</h3>
                        </div>

                        <div>
                            <div className="flex gap-3">
                                <h2 className={`text-2xl font-semibold ${onSale ? 'flex' : 'hidden'}`}>${parseFloat(item.salePrice).toFixed(2)}</h2>
                                <h2 className={`text-2xl font-semibold ${onSale && 'line-through text-neutral-500'}`}>${parseFloat(item.price).toFixed(2)}</h2>
                            </div>

                            <div className="text-right">
                                <button onClick={removeFromCart} className="pl-4 underline text-sm">Remove</button>
                            </div>
                        </div>

                    </div>

                    <br/>
                    <h2 className="text-sm">
                        Quantity:
                        <input 
                            type="number" 
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            onChange={(event) => {
                                const newValue = event.target.value;

                                if (newValue > 0 && newValue < 10) {
                                    setQuantity(newValue);
                                    dispatchQuantity(newValue);
                                }
                            }}
                            className="appearance-none w-12 rounded-md bg-transparent border border-neutral-600 text-white p-2 focus:outline-none ml-2"
                            value={quantity}
                        />
                    </h2>

                    <p className="mt-4 text-neutral-400 line-clamp-3">{item.description}</p>
                </div>
            </div>
        </div>
    )
}

export default CartItem;