import Logo from '../../img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faYoutube, faTwitter, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import CartIcon from '../cart/CartIcon';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { selectUser } from '../auth/UserSlice';

const socials = [
    { id: 'Instagram', href: 'https://www.instagram.com/dabxlabs/', icon: faInstagram },
    { id: 'Youtube', href: 'https://www.youtube.com/channel/UCNcXZbFFYoBMnOQBXS0sC7g', icon: faYoutube },
];

/*
    { id: 'Facebook', href: 'https://www.facebook.com', icon: faFacebookSquare },
    { id: 'Twitter', href: 'https://www.facebook.com', icon: faTwitter },
*/

const Navbar = () => {
    const user = useSelector(selectUser);
    const menuItems = [
        { text: 'Home', href: '/' },
        { text: 'About', href: '/about' },
        { 
            text: 'Products', 
            href: '/products', 
            subItems: [
                { text: 'Dab Rigs', href: 'dab-rigs' },
                { text: 'Quartz Bangers', href: 'quartz-bangers' },
                { text: 'CBD Products', href: 'cbd' },
                { text: 'Marble Sets', text2: 'Terp Pearls / Carb Caps', href: 'marble-sets' },
                { text: 'Accessories', text2: 'Adapters / Merch', href: 'accessories' },
                { text: 'Kits', href: 'kits' },
            ] 
        },
        { text: 'Contact', href: '/contact' },
    ];

    const accountMenu = { text: 'My Account',  href: '/account', }
    let accountSubItems = [];

    if (user) {
        if (user.admin) {
            accountSubItems.push({ text: 'Admin', href: 'admin' });
        }
        accountSubItems.push({ text: 'Profile', href: 'profile' });
        accountSubItems.push({ text: 'Log Out', href: 'logout' });
    }
    else {
        accountSubItems.push({ text: 'Login', href: 'login' });
        accountSubItems.push({ text: 'Register', href: 'register' });
    }

    accountMenu.subItems = accountSubItems;
    menuItems.push(accountMenu);
    
    const hamburgerMenuItems = [menuItems[0], menuItems[1], menuItems[3], menuItems[2], menuItems[4]];

    return (
        <div className="container mx-auto">
            <NavbarTop menuItems={menuItems} hamburgerMenuItems={hamburgerMenuItems}/>
            <NavbarBottom menuItems={menuItems} hamburgerMenuItems={hamburgerMenuItems}/>
        </div>
    );
};
const variants = {
    open: { display: 'flex', opacity: 1, left: "0px" },
    closed: { display: 'none', opacity: 0, left: "-400%" },
}

const variantsBg = {
    open: { display: 'flex', opacity: 0.6 },
    closed: { display: 'none', opacity: 0  },
}

const NavbarTop = (props) => {
    const { hamburgerMenuItems } = props;
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <div className="relative md:mt-10 mb-5 pb-5 flex flex-column space-between items-center border-b border-white">
                <div className="hidden md:flex flex-auto justify-start space-x-4 basis-0 text-3xl">
                    { 
                        socials.map((social) => 
                            <a key={social.id} className="group" target="_blank" rel="noreferrer" href={social.href}>
                                <FontAwesomeIcon className="group-hover:text-neutral-400 transition ease-in-out duration-300" icon={social.icon}/>
                            </a>
                        )
                    }
                </div>

                <div className="flex flex-auto md:justify-center basis-0 group">
                    <Link to="/">
                        <img alt="logo" src={Logo} className="w-48 md:w-64 group-hover:opacity-75 cursor-pointer transition ease-in-out duration-300"/>
                    </Link>
                </div>

                <div className="flex flex-auto justify-end basis-0 items-center">
                    <Link to="/cart" className="group">
                        <CartIcon/>
                    </Link>

                    <div onClick={() => setOpen(true)} className="hamburger group hover:cursor-pointer ml-6 md:hidden">
                        <div className="h-0.5 w-6 mb-1 bg-white group-hover:bg-neutral-400 transition ease-in-out duration-300"/>
                        <div className="h-0.5 w-6 mb-1 bg-white group-hover:bg-neutral-400 transition ease-in-out duration-300"/>
                        <div className="h-0.5 w-6 mb-1 bg-white group-hover:bg-neutral-400 transition ease-in-out duration-300"/>
                    </div>
                </div>
            </div>

            {
                <motion.div animate={isOpen ? "open" : "closed"} variants={variantsBg} className='fixed h-full w-full top-0 left-0 z-49 bg-black'/>
            }

            <motion.div 
                animate={isOpen ? "open" : "closed"}
                variants={variants}
                className={`absolute top-6 mx-auto pl-6 pr-6 pb-6 left-0 right-0 h-auto drop-shadow-2xl  w-11/12 bg-neutral-900 rounded-md z-50`}
            >
                <div className="container">
                    <div className="mt-4 mb-5 pb-5 flex flex-column space-between items-center border-b border-white">
                        <div className="flex flex-auto basis-0 items-center text-neutral-400">
                            <div className="text-left font-semibold mt-6">
                                MENU
                            </div>

                            <div onClick={() => setOpen(false)} className="ml-auto">
                                <FontAwesomeIcon className="text-xl hover:text-neutral-400 transition ease-in-out duration-300 cursor-pointer" icon={faXmark}/>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col">
                        {
                            hamburgerMenuItems.map((menuItem) => 
                                <>
                                    { 
                                        menuItem.subItems 
                                            ?   <>
                                                    <h1 key={menuItem.text} className="text-neutral-400 pb-4 mb-4 border-b border-white uppercase">{menuItem.text}</h1>
                                                    {
                                                        menuItem.subItems.map((subItem) => 
                                                            <Link key={subItem.text} to={`${menuItem.href}/${subItem.href}`}>
                                                                <div className="h-18 flex bg-neutral-800 rounded-md w-full p-4 mb-6 flex-col">
                                                                    <h3>{subItem.text}</h3>
                                                                    <h4 className="italic text-neutral-500">{subItem.text2}</h4>
                                                                </div>

                                                            </Link>
                                                        )
                                                    }
                                                </>
                                            :   <Link key={menuItem.text} to={`${menuItem.href}`}>
                                                    <div className="h-18 flex items-center bg-neutral-800 rounded-md w-full p-4 mb-6">
                                                        {menuItem.text}
                                                    </div>
                                                </Link>
                                    }
                                </>
                            )
                        }

                        <h1 className="text-neutral-400 pb-4 mb-4 border-b border-white">SOCIALS</h1>

                        <div>
                            { 
                                socials.map((social) => 
                                    <a key={social.id} className="mr-2 group text-3xl" target="_blank" rel="noreferrer" href={social.href}>
                                        <FontAwesomeIcon className="group-hover:text-neutral-400 transition ease-in-out duration-300" icon={social.icon}/>
                                    </a>
                                )
                            }
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    )
};

const NavbarBottom = (props) => {
    const { menuItems } = props;

    return ( 
        <div className="hidden md:flex mb-10 pb-5 flex-column space-between items-center">
            <div className="flex grow justify-center space-x-12">
                { 
                    menuItems.map((menuItem) => 
                        <span key={menuItem.text} className="group relative">
                            <Link className="hover:text-neutral-400" to={menuItem.subItems ? "" : menuItem.href}>{menuItem.text}</Link>

                            { menuItem.subItems && 
                                <div className="w-96 absolute right-0 z-10">
                                    <div className="h-4 bg-transparent hidden group-hover:block"/>

                                    <div className="p-6 bg-neutral-900  text-neutral-400 rounded-md hidden group-hover:block">
                                        <h5 className="text-neutral-500 text-xs font-bold mb-2 uppercase">{menuItem.text}</h5>
                                        {
                                            menuItem.subItems.map((subItem) => 
                                                <Link key={subItem.text} className="navItem" to={`${menuItem.href}/${subItem.href}`} replace>
                                                    <div className="z-100 p-4 mb-2 rounded-md last:mb-0 flex flex-column justify-between items-center transition ease-in-out duration-300">
                                                        <div>
                                                            <h4 className="transition ease-in-out duration-300">{subItem.text}</h4>
                                                            <h3 className="text-neutral-500 italic">{subItem.text2}</h3>
                                                        </div>

                                                        <FontAwesomeIcon className="text-neutral-500 transition ease-in-out duration-300" icon={faChevronRight}/>
                                                    </div>
                                                </Link>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </span>
                    )
                }
            </div>
        </div>
    )
}

export default Navbar;