import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CartItem from './CartItem';
import { selectCart } from './CartSlice';
import { ReactComponent as Loading }  from '../../img/loadingicon.svg';
import { useNavigate } from 'react-router-dom';
import { Button } from '@tremor/react';

const CartList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const cart = useSelector(selectCart);

  const checkout = async () => {
    setLoading(true);

    navigate('/checkout');

    setLoading(false);
  };

  return (
    <div className="w-full md:w-9/12 flex flex-col items-center">
      { cart.items.length > 0 && 
        <>
          { cart.items.map(item => <CartItem key={item.uniqueID} item={item}/>) }

          <div className="w-full md:w-9/12 flex flex-col items-end border-t border-b border-neutral-600 pb-6 mb-6">
            <div className='ml-auto mt-6 text-right'>
              <h1 className='font-semibold text-1xl text-neutral-300'>Sub-Total</h1>
              <h3 className="font-bold text-3xl">${cart.items.map(x => parseFloat(x.salePrice && x.salePrice > 0 ? x.salePrice : x.price) * x.quantity).reduce((a, b) => a + b, 0).toFixed(2)}</h3>
            </div>
          </div>

          <div className="w-full md:w-9/12 flex flex-col items-end">
            <Button color="white" className="text-black pl-14 pr-14 pb-6 pt-6" size="xl" onClick={checkout} loading={loading}>
              Checkout
            </Button>
          </div>
        </> 
      }
      
      { cart.items.length === 0 && <div className="pt-24 pb-24"><h4 className='text-1xl'>You have no items in your cart.</h4></div>}
    </div>
  );
}

export default CartList;
