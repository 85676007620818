import Navbar from '../components/navbar/Navbar';
import Footer from '../components/navbar/Footer';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

const About = () => {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <Navbar/>

            <Helmet>
                <title>Dabxlabs — About</title>
                <meta name="description" content="The DabxLabs team has come together with a mission to provide you with the best customer service, fastest turnaround and most importantly the highest quality glassware and quartz without breaking the bank." />
            </Helmet>


            <div className='container mx-auto justify-center items-center flex flex-col'>
                <h1 className="text-5xl font-hero font-bold">About</h1>

                <div className="mt-12 mb-12 flex justify-center items-center flex-col w-full md:w-1/2 text-lg">
                    <p>
                    We searched high and low and found the options for Australians are; Extremely expensive art work or the cheapest quartz available to consume their concentrates… until now…
                    <br/><br/>
                    The DabxLabs team has come together with a mission to provide you with the best customer service, fastest turnaround and most importantly the highest quality glassware and quartz without breaking the bank.
                    <br/><br/>
                    We have the widest range of high quality quartz bangers in a huge range of styles and all the accessories from recyclers to dab thermometers for that perfect flavour and everything you’ll need to get the greatest flavours and most superior effects out of your precious oils and not wait weeks for the privilege to do so.
                    <br/><br/>
                    We care about our people and their experiences and strive to offer care and support before, during and after sales so don’t ever hesitate to contact with any questions or comments, we are here to help.
                    <br/><br/>
                    Welcome to the DabxLabs Family
                    </p>
                </div>
            </div>

            <Footer/>
        </motion.div>
    )
}

export default About;