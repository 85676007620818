import { ArrowLeftIcon } from "@heroicons/react/solid";
import { 
    Grid, 
    Card,
    Title,
    Badge,
    Button,
    Col,
    Divider,
} from "@tremor/react";
import ImageHelper from '../components/ImageHelper';
import { getFirebaseDate, getStatusColor } from '../pages/Admin';
import { doc, updateDoc } from '@firebase/firestore';
import { firestore } from '../firebase';
import { useSnackbar } from 'react-simple-snackbar';
import { useState } from "react";

const EditableOrder = (props) => {
    const { order: orderProp, onClick, onSave, nonEditable } = props;
    const [order, setOrder] = useState(orderProp);
    const shippingCost = order.shippingItem?.price ?? 0;
    const subtotal = order.items.map(x => parseFloat(x.salePrice && x.salePrice > 0 ? x.salePrice : x.price) * x.quantity).reduce((a, b) => a + b, 0);
    const total = subtotal + shippingCost;
    const [openSnackbar] = useSnackbar({ position: 'top-center', style: { backgroundColor: '#22c55e', color: 'white', fontWeight: 'bold' }});

    const setStatus = async (status) => {
        const document = doc(firestore, "orders", order.id);
        await updateDoc(document, { status });

        order.status = status;
        setOrder(order); 
        openSnackbar(`Successfully set product to ${status}.`);
        onSave(order);
    }

    const markAsPaid = () => setStatus("Paid"); 
    const markAsRejected = () => setStatus("Rejected");
    const markAsOutForDelivery = () => setStatus("Out for Delivery");
    const markAsComplete = () => setStatus("Fulfilled");

    return (
        <>
            <Card className="mt-6 text-black">
                <div>
                    <Button onClick={onClick} variant="light" icon={ArrowLeftIcon} iconPosition="left">Back</Button>
                </div>

                <div className="mt-6">
                    <Title className="mt-6 font-bold text-3xl">Order #{order.orderNumber}</Title>
                    <Divider/>

                    <div className="flex flex-row gap-24 mt-6">
                        <div>
                            <h5 className="block text-neutral-600 text-sm font-bold mb-2">Order Status</h5>
                            <Badge className={`text-white font-semibold ${getStatusColor(order.status)}`}>{order.status}</Badge>
                        </div>

                        <div>
                            <h5 className="block text-neutral-600 text-sm font-bold mb-2">Order Date</h5>
                            <h6>{getFirebaseDate(order.date)}</h6>
                        </div>

                        <div>
                            <h5 className="block text-neutral-600 text-sm font-bold mb-2">Customer</h5>
                            <h6>{order.email}</h6>

                            <h6 className="mt-4">{order.firstName} {order.lastName}</h6>
                            <p>{order.streetAddress}</p>
                            <p>{order.streetAddress2}</p>
                            <p>{order.suburb} {order.postcode}, {order.state}</p>
                            <p>{order.country}</p>

                            <p className="mt-4">{order.phone}</p>
                        </div>

                        <div>
                            <h5 className="block text-neutral-600 text-sm font-bold mb-2">Shipping Information</h5>
                            <h6>{order.shippingItem?.name ?? ''}</h6>
                            <h6>{order.shippingItem?.estimated ?? ''}</h6>
                        </div>
                    </div>
                </div>
            </Card>

            <Grid numCols={2} numColsSm={1} numColsMd={2} className="gap-4 mt-4 text-black">
                <Col>
                    <Card>
                        <Title className="mb-6 text-2xl">Items</Title>
                        { order.items.length > 0 && 
                            <div className="max-w-[500px]">
                                { order.items.map((item) => 
                                <>
                                    <div className="flex flex-row items-center gap-6">
                                        <div className="w-22 h-22">
                                            <div className="w-24 h-24 bg-black rounded-lg flex align-center">
                                                <img className="h-full w-full object-cover rounded-lg" alt={item.name} src={ImageHelper.getMainImage(item.images).src}/>
                                            </div>
                                        </div>

                                        <div>
                                            <h6 className="font-semibold mb-4">{item.name}</h6>
                                            <p className="text-neutral-500 mb-4 line-clamp-3">{item.description}</p>
                                            <div className="flex text-md gap-3">
                                                <h2 className={`font-semibold ${item.salePrice && item.salePrice > 0 ? 'flex' : 'hidden'}`}>${parseInt(item.salePrice).toFixed(2)} AUD</h2>
                                                <h2 className={`font-semibold ${item.salePrice && item.salePrice > 0 && 'line-through text-neutral-500'}`}>${parseInt(item.price).toFixed(2)} AUD</h2>

                                                x {item.quantity}
                                            </div>
                                        </div>
                                    </div>
                                    { <Divider/> }
                                </>
                                )}
                            </div>
                        }
                    </Card>
                </Col>

                <Col>
                    <Card>
                        <Title className="mb-6 text-2xl">Totals</Title>
                        <div className="flex flex-row justify-between">
                            <h4 className="text-neutral-500">Subtotal</h4>
                            <h5 className="text-neutral-800 font-semibold">${subtotal.toFixed(2)}</h5>
                        </div>

                        <div className="flex flex-row justify-between">
                            <h4 className="text-neutral-500">Shipping</h4>
                            <h5 className="text-neutral-800 font-semibold">${shippingCost.toFixed(2)}</h5>
                        </div>

                        <Divider/>

                        <div className="flex flex-row justify-between">
                            <h4 className="text-neutral-500">Total</h4>

                            <div className="flex flex-row justify-center items-center">
                                <h5 className=" text-neutral-400 mr-2">AUD</h5>
                                <h5 className="text-2xl text-neutral-800 font-semibold">${total.toFixed(2)}</h5>
                            </div>
                        </div>
                    </Card>

                    { (order.status !== "Fulfilled" && !nonEditable) && 
                        <Card className="mt-4">
                            <Title className="mb-6 text-2xl">Actions</Title>

                            <div className="gap-2 flex">
                                { order.status === "Placed" && <Button color="green" onClick={markAsPaid}>Mark as Paid</Button> }
                                { order.status === "Placed" && <Button color="red" onClick={markAsRejected}>Mark as Rejected</Button> }
                                { order.status === "Paid" && <Button onClick={markAsOutForDelivery}>Mark as Out for Delivery</Button> }
                                { order.status === "Out for Delivery" && <Button color="green" onClick={markAsComplete}>Mark as Complete</Button> }
                            </div>
                        </Card>
                    }
                </Col>
            </Grid>
        </>
    ) 
}

export default EditableOrder