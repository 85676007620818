import Navbar from '../components/navbar/Navbar';
import Footer from '../components/navbar/Footer';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Button, Card } from '@tremor/react';
import { useState } from 'react';
import { getAuth, sendEmailVerification } from '@firebase/auth';
import UserWrapper from './UserWrapper';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../components/auth/UserSlice';

const VerifyEmail = () => {
    const auth = getAuth();
    const navigate = useNavigate();
    const [resendDisabled, setResendDisabled] = useState(false);
    const user = useSelector(selectUser);

    useEffect(() => {
        if (user) {
            if (user.emailVerified) {
                navigate('/account/profile');
            }
        }
        else {
            navigate('/');
        }
    }, []);

    const resend = async () => {
        setResendDisabled(true);

        try {
            await sendEmailVerification(auth.currentUser);
        }
        catch (e) {
            console.error(e);
        }

        setResendDisabled(false);
    };

    return (
        <>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                <Navbar/>

                <Helmet>
                    <title>Dabxlabs — Verify Email</title>
                    <meta name="robots" content="noindex" />
                </Helmet>

                <div className='container mx-auto justify-center items-center flex flex-col'>
                    <div className="flex justify-center items-center flex-col w-full max-w-[500px]">
                        <Card className="w-full text-black flex items-center justify-center flex-col text-center">
                            <h2 className="text-xl font-semibold mb-4">Verify your email address</h2>

                            <p>
                                A verification email has been sent to your email address. Follow the instructions in the email to verify your account.
                            </p>

                            <Button onClick={resend} disabled={resendDisabled} color="black" className="mt-12">Resend Email</Button>
                        </Card>
                    </div>
                </div>

                <Footer/>
            </motion.div>
        </>
    )
}

export default VerifyEmail;