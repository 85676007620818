import { initializeApp } from "@firebase/app";
import { getStorage } from "@firebase/storage";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCfpkJjYPraA_dLPkab69VWYgV1L5imnZY",
    authDomain: "dabxlabs.firebaseapp.com",
    databaseURL: "https://dabxlabs-default-rtdb.firebaseio.com",
    projectId: "dabxlabs",
    storageBucket: "dabxlabs.appspot.com",
    messagingSenderId: "891350466366",
    appId: "1:891350466366:web:6d54f688cd8986c920f66f"
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);
const firestore = getFirestore(app);

export { storage, firestore };