import Item from '../components/Item';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/navbar/Footer';
import { useEffect, useState, useRef } from 'react';
import { ReactComponent as Loading }  from '../img/loadingicon.svg'
import { motion } from "framer-motion";
import FirestoreHelper from '../components/FirestoreHelper';
import dataset from '../dataset.json';
import CategoryHelper from '../components/CategoryHelper';
import { addDoc, collection } from '@firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from '@firebase/storage';
import { firestore } from '../firebase';

const Home = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        FirestoreHelper.getFeatured().then((items) => setItems(items)).finally(() => setLoading(false));
    }, []);

    const containerRef = useRef();
    const [mouseDown, setMouseDown] = useState(false);
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(null);

    let startDragging = function (e) {
        setMouseDown(true);

        const slider = containerRef.current;
        setStartX(e.pageX - slider.offsetLeft);
        setScrollLeft(slider.scrollLeft);
    };

    const stopDragging = function (event) { 
        setTimeout(() => {
            setMouseDown(false);
        }, 500);
    };

    const mouseMove = (e) => {
        e.preventDefault();
        const slider = containerRef.current;

        if(!mouseDown) { return; }

        const x = e.pageX - slider.offsetLeft;
        const scroll = x - startX;
        slider.scrollLeft = scrollLeft - scroll;
    }

    return (
        <motion.div key="home" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }}>
            <Navbar/>

            <div className='container mx-auto'>
                <div className="mt-24 md:mt-48 mb-24 md:mb-48">
                    <h1 className="antialiased text-5xl md:text-7xl font-hero font-bold tracking-tight">High quality quartz & gorgeous glassware for the Australian Aficionado</h1>
                    <h3 className="antialiased text-xl md:text-2xl font-hero font-regular text-neutral-500 mt-10">Discrete shipping and fast express post provided for Australian customers.</h3>
                </div>

                <div className="mt-24 md:mt-48 mb-24 md:mb-48">
                    <div className="mb-6 border-b border-white pb-4 w-64">
                        <h4 className="antialiased text-2xl font-hero font-semibold text-white">Trending Now</h4>
                    </div>

                    <div
                        className="flex flex-row space-x-12 flex-nowrap overflow-x-scroll container-snap snap-x snap-mandatory md:snap-none"
                        onMouseDown={startDragging}
                        onMouseUp={stopDragging}
                        onMouseLeave={stopDragging}
                        onMouseMove={mouseMove}
                        ref={containerRef}
                    >
                        { loading && <Loading/>}
                        { 
                            items.length > 0 && items.filter((item) => item.stock > 0).map((item) => <Item key={item.id} item={item} isDragging={mouseDown}/>) 
                        }
                    </div>
                </div>
            </div>

            <Footer/>
        </motion.div>
    )
}

export default Home;