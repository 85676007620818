import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ageReducer from '../components/age-verification/AgeVerificationSlice';
import cartReducer from '../components/cart/CartSlice';
import userReducer from '../components/auth/UserSlice';

const persistConfig = { key: 'root', storage };

const reducers = combineReducers({
  cartReducer,
  ageReducer,
  userReducer
})

const persistReducers = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);