import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectCart, addItem, removeItem, clear } from './cart/CartSlice';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar';
import CategoryHelper from './CategoryHelper';
import ImageHelper from './ImageHelper';
import InStock from './InStock';

const Item = (props) => {
    const { item } = props;
    const { id, name, category, images, price, salePrice } = item;

    const uniqueID = id + (Math.random() + 1).toString(36).substring(7);
    const dispatch = useDispatch();
    const [openSnackbar] = useSnackbar({ position: 'top-center', style: { backgroundColor: '#22c55e', color: 'white', fontWeight: 'bold' }});
    const navigate = useNavigate();
    const productID = `/product/${id}`;

    const onClickCart = () => {
        dispatch(addItem({uniqueID, ...item }));
        openSnackbar('Item successfully added to cart.');
    };

    const onClickItem = () => {
        navigate(productID);
    }

    const onSale = salePrice && salePrice > 0;

    return (
        <div className={`w-96 min-w-full sm:min-w-[350px] max-w-[500px] snap-center product text-left`}>
            <div className="group relative h-72 overflow-hidden rounded-md bg-black">
                <Link to={productID} alt={name}>
                    { images.length > 0 && 
                        <>
                        <img
                            key={productID}
                            className="h-full w-full aspect-[4/3] object-cover object-top flex group-hover:hidden transition ease-in-out duration-900" 
                            src={ImageHelper.getMainImage(images).src} 
                            alt="Item"/>

                        <img
                            key={productID}
                            className="h-full w-full aspect-[4/3] object-cover object-top hidden group-hover:flex transition ease-in-out duration-900" 
                            src={ImageHelper.getSecondaryImage(images).src} 
                            alt="Item"/>
                        </>
                    }

                    <div className="absolute right-2 top-2">
                        <InStock onlyOutOfStock stock={item.stock}/>
                    </div>
                </Link>
            </div>

            <div className="pt-5 flex flex-row flex-nowrap justify-between leading-snug hover:cursor-pointer" onClick={onClickItem}>
                <div>
                    <div className="min-h-[7rem] pb-2">
                        <h2 className="text-xl font-semibold mr-12">{name}</h2>
                        <h5 className="text-md mt-2 italic text-gray-300">{CategoryHelper.getCategoryTitle(category)}</h5>
                    </div>

                    <div className="flex gap-3">
                        <h2 className={`text-2xl font-semibold ${onSale ? 'flex' : 'hidden'}`}>${parseInt(salePrice).toFixed(2)}</h2>
                        <h2 className={`text-2xl font-semibold ${onSale && 'line-through text-neutral-500'}`}>${parseInt(price).toFixed(2)}</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Item;