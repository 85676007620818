import Navbar from '../components/navbar/Navbar';
import Footer from '../components/navbar/Footer';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { selectUser } from '../components/auth/UserSlice';
import { useEffect, useState } from 'react';
import { ReactComponent as Loading }  from '../img/loadingicon.svg';
import { 
    Tab, 
    TabList, 
    Card,
    Table,
    TableHead,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    Badge,
    Dropdown,
    DropdownItem,
} from "@tremor/react";
import { useLocation, useNavigate } from 'react-router-dom';
import FirestoreHelper from '../components/FirestoreHelper';
import EditableOrder from '../components/EditableOrder';
import { getFirebaseDate, getStatusColor } from './Admin';

const getAddress = (userDetails) => {
    const address = userDetails && userDetails.addresses && userDetails.addresses[0];
    return address && `${address.streetAddress} ${address.streetAddress2} ${address.suburb} ${address.postcode} ${address.state} ${address.country}`
}

const Profile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(selectUser);
    const [selectedView, setSelectedView] = useState(location.state?.view ?? "1");
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState(null);
    const [orders, setOrders] = useState([]);
    const [editingItem, setEditingItem] = useState(location.state?.item);
    const [dropdownVal, setDropdownVal] = useState(null);

    useEffect(() => {
        if (user == null) {
            navigate("/", { replace: true });
        }
        else {
            FirestoreHelper
                .getUserDetails(user)
                .then((details) => setUserDetails(details))
                .then(() => FirestoreHelper.getAllOrders(user).then((allOrders) => setOrders(allOrders)))
                .finally(setLoading(false));
        }
    }, [navigate, user]);

    return (
        <>
            <Helmet>
                <title>Dabxlabs — Profile</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <Navbar/>

            {loading && 
            <div className='container mx-auto mt-16 mb-32'>
                <Loading/>
            </div>
            }

            { !loading &&
            <div className='container mx-auto mt-16 mb-32'>
                <h1 className="text-5xl font-hero font-bold">Profile</h1>

                {
                    (selectedView === "1" || selectedView === "2") &&
                    <TabList
                        color="white"
                        value={selectedView}
                        onValueChange={(value) => { 
                            navigate('/account/profile', { state: { view: value, item: null }});
                            setSelectedView(value);
                        }}
                        className="mt-6"
                    >
                        <Tab value="1" text="Personal Information" />
                        <Tab value="2" text="Order History" />
                    </TabList>
                }

                    { selectedView === "1" && (
                    <>
                    <Card className="mt-6 text-black">
                        <div className="flex flex-row justify-between items-center">
                            <h1 className="text-black text-lg font-bold">Personal Information</h1>
                        </div>

                        <div className="mt-8 flex flex-col gap-8">
                            <div>
                                <h5 className="block text-neutral-600 text-sm font-bold mb-2">Email</h5>
                                <h6>{user.email}</h6>
                            </div>

                            <div>
                                <h5 className="block text-neutral-600 text-sm font-bold mb-2">Created On</h5>
                                <h6>{userDetails && getFirebaseDate(userDetails.createdOn)}</h6>
                            </div>

                            <div>
                                <h5 className="block text-neutral-600 text-sm font-bold mb-2">Saved Address</h5>
                                <h6>{userDetails && getAddress(userDetails)}</h6>
                            </div>

                            <div>
                                <h5 className="block text-neutral-600 text-sm font-bold mb-2">Orders Placed</h5>
                                <h6>{orders.length}</h6>
                            </div>
                        </div>
                    </Card>
                </>
                )}

                { selectedView === "2" && (
                    <>
                    <Card className="mt-6 min-h-[800px]">
                        <div className="flex flex-row justify-between items-center">
                            <h1 className="text-black text-lg font-bold">Order History</h1>
                        </div>

                        <Table className="mt-6 mb-[50px] min-h-[800px]">
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell>Actions</TableHeaderCell>
                                    <TableHeaderCell>Order #</TableHeaderCell>
                                    <TableHeaderCell>Status</TableHeaderCell>
                                    <TableHeaderCell>Customer</TableHeaderCell>
                                    <TableHeaderCell>Total Cost</TableHeaderCell>
                                    <TableHeaderCell>Items</TableHeaderCell>
                                    <TableHeaderCell>Order Date</TableHeaderCell>
                                    <TableHeaderCell>Shipping Address</TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.sort((a, b) => getFirebaseDate(b.date).localeCompare(getFirebaseDate(a.date))).map((order) => (
                                    <TableRow key={order.orderNumber}>
                                          <TableCell>
                                            <Dropdown
                                                className="mt-2 min-w-[16px]"
                                                value={dropdownVal}
                                                onValueChange={(value) => {
                                                    if (value === "EDT-ORD") {
                                                        setSelectedView("EDT-ORD");
                                                        setEditingItem(order);
                                                        navigate('/account/profile', {state: { view: "EDT-ORD", item: order }});
                                                    }
                                                }}
                                                placeholder="Actions"
                                            >
                                                <DropdownItem className="text-blue-500 font-semibold" value="EDT-ORD" text="View Order" />
                                            </Dropdown>
                                        </TableCell>
                                        <TableCell>#{order.orderNumber}</TableCell>
                                        <TableCell><Badge className={`text-white font-semibold ${getStatusColor(order.status)}`}>{order.status}</Badge></TableCell>
                                        <TableCell>{order.firstName} {order.lastName} ({order.email})</TableCell>
                                        <TableCell>${order.items.map(x => parseFloat(x.price) * x.quantity).reduce((a, b) => a + b, 0)} AUD</TableCell>
                                        <TableCell>{order.items.map(x => x.quantity).reduce((a, b) => a + b, 0)}</TableCell>
                                        <TableCell>{getFirebaseDate(order.date)}</TableCell>
                                        <TableCell>{order.streetAddress} {order.streetAddress2} {order.suburb} {order.postcode} {order.state} {order.country}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Card>
                </>
                )}

                {
                    (selectedView === "EDT-ORD" && editingItem) && 
                    <>
                        <EditableOrder
                            nonEditable
                            order={editingItem}
                            onClick={() => {
                                setSelectedView("2");
                                navigate('/account/profile', {state: { view: "2", item: null }})
                            }} 
                        />
                    </>
                }
            </div>
            }

            <Footer/>
        </>
    )
}

export default Profile;