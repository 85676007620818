const InStock = (props) => {
    const { stock, onlyOutOfStock } = props;
    const hasStock = stock > 0;
    const color = hasStock ? 'green': 'red';

    if (onlyOutOfStock && hasStock) {
        return;
    }

    return (<h3 className={`text-white bg-${color}-700 font-bold text-sm rounded-lg px-10 py-3`}>{hasStock ? "In Stock" : "Out of Stock"}</h3>)
}

export default InStock;