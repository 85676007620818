import Footer from "../components/navbar/Footer"
import Navbar from "../components/navbar/Navbar"
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSnackbar } from 'react-simple-snackbar';
import { addItem } from "../components/cart/CartSlice";
import { useDispatch } from "react-redux/es/exports";
import CategoryHelper from "../components/CategoryHelper";
import { motion } from "framer-motion";
import FirestoreHelper from "../components/FirestoreHelper";
import ImageHelper from "../components/ImageHelper";
import { Button, Col, Grid } from "@tremor/react";
import InStock from "../components/InStock";
import { faChevronLeft, faChevronRight, faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProductDetails = () => {
    const { productId } = useParams();
    const [item, setItem] = useState(null);

    const dispatch = useDispatch();
    const [openSnackbar] = useSnackbar({ position: 'top-center', style: { backgroundColor: '#22c55e', color: 'white', fontWeight: 'bold' }});
    const [openErrorSnackbar] = useSnackbar({ position: 'top-center', style: { backgroundColor: 'red', color: 'white', fontWeight: 'bold' }});

    useEffect(() => {
        FirestoreHelper.getItemById(productId).then((retrievedItem) => {
            retrievedItem.id = productId;
            retrievedItem.images = retrievedItem.images.sort((a, b) => {
                if (a.type === "default") {
                    return 1;
                } else if (a.type === "secondary") {
                    return 1;
                }  else if (b.type === "default") {
                    return -1;
                } else if (b.type === "secondary") {
                    return -1;
                }

                return 0;
            });
            setItem(retrievedItem);
            setImage(ImageHelper.getMainImage(retrievedItem.images));
        });
    }, [productId]);

    const addToCart = () => {
        if (item.stock <= 0) {
            openErrorSnackbar('Item is out of stock.');
        }
        else {
            const uniqueID = item.id + (Math.random() + 1).toString(36).substring(7);
            dispatch(addItem({uniqueID, ...item }));
            openSnackbar('Item successfully added to cart.');
        }
    };

    const isMobile = window.innerWidth <= 800;
    const [selectedImg, setImage] = useState(null);
    const [fullscreen, setFullscreen] = useState(null);
    const onSale = item && item.salePrice && item.salePrice > 0;

    const setNextImage = () => {
        const allImages = item.images;
        const currentIdx = allImages.findIndex((x) => x.src === selectedImg.src);

        setImage(allImages[currentIdx + 1] ?? allImages[0]);
    }

    const setPreviousImage = () => {
        const allImages = item.images;
        const currentIdx = allImages.findIndex((x) => x.src === selectedImg.src);

        setImage(allImages[currentIdx - 1] ?? allImages[allImages.length - 1]);
    }

    useEffect(() => {
        if (fullscreen) {
            document.documentElement.style.overflowY = 'hidden';
        }

        return () =>{
            document.documentElement.style.overflowY = 'auto';
        }
    }, [fullscreen]);

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            { item && <Helmet><title>Dabxlabs — {item.name}</title></Helmet> }

            <Navbar/>

            {
                fullscreen && 
                <motion.div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black bg-opacity-70 z-10" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
                    <div className="flex max-h-[80vh] relative">
                        <img 
                            className={`object-contain rounded-lg h-full max-h-[80vh] p-12`} 
                            src={selectedImg.src} 
                            alt="Item"/> 

                        { item.images.length > 1 &&
                            <>
                                <FontAwesomeIcon onClick={() => setPreviousImage()} className="group-hover:text-neutral-400 hover:cursor-pointer transition text-4xl ease-in-out duration-300 absolute top-[50%] left-4" icon={faChevronLeft}/>     
                                <FontAwesomeIcon onClick={() => setNextImage()} className="group-hover:text-neutral-400 hover:cursor-pointer transition text-4xl ease-in-out duration-300 absolute top-[50%] right-4" icon={faChevronRight}/>     
                            </>
                        }
                    </div>

                    <FontAwesomeIcon onClick={() => setFullscreen(false)} className="group-hover:text-neutral-400 hover:cursor-pointer transition text-4xl ease-in-out duration-300 absolute top-8 right-8" icon={faXmark}/> 
                </motion.div>
            }

            { 
                item && 
                    <div className="container mx-auto flex flex-row flex-wrap gap-14 md:mt-24 md:mb-32">
                        <Grid numColsSm={1} numColsMd={2} className="gap-12">
                            <Col>
                                <div className="rounded-lg overflow-hidden flex justify-start relative">
                                    <img 
                                        onClick={() => setFullscreen(true)}
                                        className={`object-contain rounded-lg hover:cursor-pointer ${isMobile ? 'w-full' : 'max-h-[600px]'}`} 
                                        src={selectedImg.src} 
                                        alt="Item"/>
                                </div> 

                                <div className="mt-4 w-full">
                                    <Grid numCols={2} numColsSm={2} numColsMd={4} className={`gap-4 ${isMobile && 'relative flex flex-row overflow-x-scroll'}`}>
                                        {
                                            item.images.map((itemImage) =>                                        
                                                <Col>
                                                    <div className={`flex bg-neutral-900 rounded-lg hover:cursor-pointer ${isMobile ? 'h-16 w-24' : 'h-36 w-full'}`}>
                                                        <img 
                                                            onClick={() => setImage(itemImage)}
                                                            className="object-cover w-full h-full rounded-lg" 
                                                            src={itemImage.src} 
                                                            alt="Item"/>
                                                    </div>
                                                </Col> 
                                            )
                                        }
                                    </Grid>
                                </div>
                            </Col>

                            <Col>
                                <div className="max-w-[550px]">
                                    <div className="flex mb-12">
                                        <InStock stock={item.stock}/>
                                    </div>

                                    <h1 className="text-5xl font-hero font-bold">{item.name}</h1>
                                    <h3 className="text-xl mt-4 text-neutral-400">{CategoryHelper.getCategoryTitle(item.category)}</h3>

                                    <p className="mt-6 whitespace-pre-wrap">
                                        {item.description}
                                    </p>

                                    <p className="mt-6">
                                            Discrete shipping and fast express post provided for Australian customers.
                                    </p>

                                    <div className="flex flex-row items-center gap-4 mt-24 text-4xl sm:text-3xl font-bold">
                                        <h2 className={`${onSale ? 'flex' : 'hidden'}`}>${parseFloat(item.salePrice).toFixed(2)}</h2>
                                        <h2 className={` ${onSale && 'line-through text-neutral-500'}`}>${parseFloat(item.price).toFixed(2)}</h2>
                                    </div>

                                    <Button onClick={addToCart} color="neutral" size="xl" className="mt-6 w-full sm:w-auto px-16 py-4">
                                        Add to Cart
                                    </Button>
                                </div>
                            </Col>
                        </Grid>
                    </div> 
            }

            <Footer/>
        </motion.div>
    )
}


export default ProductDetails;