
class CategoryHelper {
    getCategoryTitle(path) {
        const transformed = this.transform(path);

        switch (transformed) {
            case 'dab-rigs':
                return 'Dab Rigs';
            case 'quartz-bangers':
                return 'Quartz Bangers';
            case 'marble-sets':
                return 'Marble Sets';
            case 'accessories':
                return 'Accessories';
            case 'kits':
                return 'Kits';
            case 'terp-pearls':
                return 'Terp Pearls';
            case 'carb-caps':
                return 'Carb Caps';
            case 'merch':
                return 'Merch';
            case 'adapters':
            case 'adapter':
                return 'Adapters';
            case 'cbd':
                return 'CBD Products';
            default:
                throw new Error('Something went wrong.');
        }
    }

    getAllCategories() {
        return ['dab-rigs', 'quartz-bangers', 'cbd', 'marble-sets', 'accessories', 'kits'];
    }

    isItemRelated(item, path) {
        if (item.metadata && item.metadata.category) {
            const itemCategory = this.transform(item.metadata.category.trim()).toLowerCase().trim();
            
            return itemCategory === path 
                || (path === "marble-sets" && ["terp-pearls", "carb-caps"].includes(itemCategory))
                || (path === "accessories" && ["adapters", "adapter", "merch"].includes(itemCategory))
        }

        return false;
    }

    transform(path) {
        switch (path.toLowerCase().trimEnd()) {
            case 'dab rigs':
            case 'dab rig':
                return 'dab-rigs';
            case 'quartz bangers':
            case 'quartz banger':
                return 'quartz-bangers';
            case 'marble sets':
            case 'marble set':
                return 'marble-sets';
            case 'terp perals':
            case 'terp peral':
                return 'terp-pearls';
            case 'carb caps':
            case 'carb cap':
                return 'carb-caps';
            default:
                return path.toLowerCase().trimEnd();
        }
    }
}

export default new CategoryHelper();