import Navbar from '../components/navbar/Navbar';
import Footer from '../components/navbar/Footer';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../components/auth/UserSlice';
import { getAuth, signOut } from '@firebase/auth';

const Logout = () => {
    const auth = getAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        signOut(auth).then(() => {
            dispatch(logout());
            navigate('/', { replace: true });
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Dabxlabs — Logout</title>
                <meta name="robots" content="noindex" />
            </Helmet>


            <Navbar/>

            <Footer/>
        </>
    )
}

export default Logout;